import {ActionWithPayload} from "../../redux/createAction";
import {Billing} from "../../models/types/Billing";
import {
    SET_BASE_KWH_PRICE,
    SET_CONSUMER_TYPE,
    SET_CONTRACT_TYPE,
    SET_DATE_END,
    SET_DATE_START,
    SET_GLOBAL_CONSUMPTION_METRIC, SET_HAS_TRV,
    SET_OFF_PEAK_KWH_PRICE,
    SET_OFF_PEAK_START,
    SET_PEAK_KWH_PRICE,
    SET_PEAK_START,
    SET_PRICE_CONFIG,
    SET_PRM,
    SET_RESULTS_ECONOMY,
    SET_RESULTS_SELF_CONSUMED,
    SET_SUMMER_OFF_PEAK_KWH_PRICE,
    SET_SUMMER_PEAK_KWH_PRICE,
    SET_TRV,
    SET_WINTER_OFF_PEAK_KWH_PRICE,
    SET_WINTER_PEAK_KWH_PRICE
} from "./billCheckActions";


const initialState = {
    form: {
        prm: null,
        dateStart: null,
        dateEnd: null,
        consumerType: 'individual',
        tarif: {
            contractType: null,
            basekWhprice: null,
            offPeakkWhPrice: null,
            peakkWhPrice: null,
            summerOffPeakkWhPrice: null,
            summerPeakkWhPrice: null,
            winterOffPeakkWhPrice: null,
            winterPeakkWhPrice: null,
            offPeakStart: "22:00",
            peakStart: "06:00",
            TRV : null
        }
    },
    TRV : {},
    result: {}
};


export default function billCheckReducer(state = initialState, action: ActionWithPayload<Billing>) {
    switch (action.type) {
        case SET_PRICE_CONFIG:
            return {
                ...state,
                ...action.payload,
            }
        case SET_PRM: {
            return {
                ...state, form: {
                    ...state.form, prm: action.payload
                }
            };
        }
        case SET_DATE_START: {
            return {
                ...state, form: {
                    ...state.form, dateStart: action.payload
                }
            };
        }
        case SET_DATE_END: {
            return {
                ...state, form: {
                    ...state.form, dateEnd: action.payload
                }
            };
        }
        case SET_CONSUMER_TYPE: {
            return {
                ...state, form: {
                    ...state.form, consumerType: action.payload
                }
            };
        }
        case SET_CONTRACT_TYPE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, contractType: action.payload

                    }
                }
            };
        }
        case SET_PEAK_START: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, peakStart: action.payload

                    }
                }
            };
        }
        case SET_OFF_PEAK_START: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, offPeakStart: action.payload

                    }
                }
            };
        }
        case SET_BASE_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, basekWhprice: action.payload

                    }
                }
            };
        }
        case SET_HAS_TRV : {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, TRV: action.payload

                    }
                }
            };
        }
        case SET_TRV : {
            return {
                ...state, TRV : action.payload
            }
        }
        case SET_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, peakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_OFF_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, offPeakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_WINTER_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, winterPeakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_WINTER_OFF_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, winterOffPeakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_SUMMER_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, summerPeakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_SUMMER_OFF_PEAK_KWH_PRICE: {
            return {
                ...state, form: {
                    ...state.form, tarif: {
                        ...state.form.tarif, summerOffPeakkWhPrice: action.payload

                    }
                }
            };
        }
        case SET_RESULTS_ECONOMY : {
            return {
                ...state, result: {
                    ...state.result,
                    economy : action.payload
                }
            };
        }
        case SET_RESULTS_SELF_CONSUMED : {
            return {
                ...state, result: {
                    ...state.result,
                    selfConsumed : action.payload
                }
            };
        }
        case SET_GLOBAL_CONSUMPTION_METRIC : {
            return {
                ...state, result: {
                    ...state.result,
                    globalConsumption : action.payload
                }
            };
        }
        default:
            return state;
    }
}

