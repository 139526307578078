import React from "react";
import error from "../../../media/Images/not-found-image.svg"
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MuiTheme from "../../../../../../config/MuiTheme";
import {HOME_PAGE} from "../../../../../../config/app-config";
import {Link} from "react-router-dom";

const enoTheme = MuiTheme();

const useStyles = makeStyles(theme => ({
    main: {
        height:"100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    btnPagesContainer: {
        fontSize: "16px",
        fontWeight: 600,
        color: "rgb(0,0,0,0.6)"
    },
    title: {
        flexWrap: "wrap",

        textTransform: "uppercase",
        color: enoTheme.palette.primary.main,
        fontWeight: "bold",
        fontSize: "min(12vmin,45px)",
    },
    description: {
        color: "#8c8c8c", // resolve : enoTheme.palette.blackToGrey.main,
        fontSize: "min(4vmin,15px)",
        fontWeight: "normal",
    },
    image: {
        paddingTop: "8vh",
        paddingBottom: "8vh",
        alignSelf: "center",
        width: "min(80vmin, 600px)",
    }
}));

export default function NotFound() {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <span className={classes.title}>page introuvable</span>
            <img className={classes.image} src={error} width="70%" alt="ErrorNotFoundImage"/>
            <Typography className={classes.description} variant="h5" component="h5">Désolé, nous n'avons pas trouvé la
                page que
                vous recherchiez. <br/>Contactez nous si le problème persiste.</Typography>
            <Link to={HOME_PAGE.route} className={classes.btnPagesContainer}>
                Retourner à l'accueil
            </Link>
        </div>);
}


