import {participantsGatewayInterface} from "../../../corelogic/usecases/participants/participantsGatewayInterface";
import {EParticipant} from "../../../corelogic/models/entities/participant/participant";
import client from "../libs/client";
import {BillingDetail} from "../../../corelogic/models/entities/participant/billingDetail";
import {EnedisDetail} from "../../../corelogic/models/entities/participant/enedisDetail";

export default class APIParticipantsGateway implements participantsGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };

    getParticipantsByJwt = async (): Promise<EParticipant[]> => {
        const {data: participants} = await client.get('/participants/by-jwt')
        let newBillingDetail: BillingDetail
        let newEnedisDetail: EnedisDetail
        return participants.map((participant: any) => {
            newBillingDetail = new BillingDetail(participant.billingDetail.id, participant.billingDetail.name, participant.billingDetail.address, participant.billingDetail.postCode, participant.billingDetail.city, participant.billingDetail.email, participant.billingDetail.phoneNumber, participant.billingDetail.defaultSellingPrice)
            newEnedisDetail = new EnedisDetail(participant.enedisDetail.prm, participant.enedisDetail.padt, participant.enedisDetail.city, participant.enedisDetail.postCode, participant.enedisDetail.entityType, participant.enedisDetail.activationRange, participant.enedisDetail.address, participant.enedisDetail.name)
            return new EParticipant(participant.id, participant.entityType, participant.userId, participant.operationId, newBillingDetail, newEnedisDetail)
        });
    }

    async updateEnedisDetail({enedisDetail, participantId}: { enedisDetail: EnedisDetail, participantId: number }): Promise<EnedisDetail> {
        const {data}: any = await client.put(`/participants/${participantId}`, {enedisDetail: enedisDetail.serialize()})
        return Promise.resolve(new EnedisDetail(data.enedisDetail.prm, data.enedisDetail.padt, data.enedisDetail.city, data.enedisDetail.postCode, data.enedisDetail.entityType, data.enedisDetail.activationRange,data.enedisDetail.address, data.enedisDetail.name))
    }
    async updateBillingDetail({billingDetail, participantId}: { billingDetail: BillingDetail, participantId: number }): Promise<BillingDetail> {
        const {data}: any = await client.put(`/participants/${participantId}`, {billingDetail: billingDetail.serialize()})
        return Promise.resolve(new BillingDetail(data.billingDetail.id, data.billingDetail.name, data.billingDetail.address, data.billingDetail.postCode, data.billingDetail.city, data.billingDetail.email,data.billingDetail.phoneNumber, data.billingDetail.defaultSellingPrice))
    }

}
