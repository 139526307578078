import {createMuiTheme} from "@material-ui/core";
import LightenDarkenColor from "../adapters/primary/libs/lightenDarkenColor";

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        blackToGrey: Palette['primary'];
        greyToWhite: Palette['primary'];
        default: Palette['primary'];
        ultraLight: Palette['primary'];
        eno2 : Palette['primary'];
    }

    interface PaletteOptions {
        blackToGrey: Palette['primary'];
        greyToWhite: Palette['primary'];
        default: Palette['primary'];
        ultraLight: Palette['primary'];
        eno2 : Palette['primary'];
    }
}
const theme = (primaryColor?: string, secondaryColor?: string) => createMuiTheme({
    palette: {
        primary: {
            dark: LightenDarkenColor(primaryColor, -10),
            main: primaryColor || 'rgb(0,191,166,1)',
            contrastText: LightenDarkenColor(primaryColor, 10),
            light: LightenDarkenColor(primaryColor, 20),
        },
        secondary: {
            dark: LightenDarkenColor(secondaryColor, -10),
            main: secondaryColor || 'rgb(200,18,220)',
            contrastText: LightenDarkenColor(secondaryColor, 10),
            light: LightenDarkenColor(secondaryColor, 20),
        },
        blackToGrey: {
            dark: '#000000',
            main: "#212121",
            contrastText: LightenDarkenColor("#000000", 20) + "",
            light: LightenDarkenColor("#000000", 30) + "",
        },
        greyToWhite: {
            dark: LightenDarkenColor("#000000", 70) + "",
            main: LightenDarkenColor("#000000", 80) + "",
            contrastText: LightenDarkenColor("#000000", 90) + "",
            light: LightenDarkenColor("#000000", 95) + "",
        },
        default: {
            dark: 'rgba(0,0,0,0.87)',
            main: '#00BFA6',
            contrastText: '#0F7339',
            light: 'rgba(255,255,255,0.87)',
        },
        ultraLight: {
            dark: LightenDarkenColor(primaryColor, 65) + "",
            main: LightenDarkenColor(primaryColor, 68) + "",
            contrastText: LightenDarkenColor(secondaryColor, 65) + "",
            light: LightenDarkenColor(secondaryColor, 68) + "",
        },
        eno2: {
            dark: '#000000',
            main: "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(90.51deg, #38EF7D -7.55%, #11998E 119.64%)",
            light: "#F1F4FA",
            contrastText: "#809FB8",
        },
    },
    typography: {
        fontSize: 15,
        fontFamily: `"Nunito Sans", sans-serif`
    },
    overrides: {
        MuiToolbar: {
            gutters: {
                paddingLeft: '16px !important',
            }
            //MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-h4 MuiTypography-alignCenter
        },
        MuiButton: {
            root: {
                color: '#F3B617',
                borderColor: `${'#F3B617'} !important`,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 15,
                backgroundColor: 'white',
                color: 'black',
                border: `solid 1px black`,
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
            }
        },
        MuiListItem: {
            root: {
                color: '#707070',
            },
            button: {
                '&:hover': {
                    color: 'black',
                }
            },
        },
        MuiTypography: {
            root: {
                alignSelf: 'center',
            },
            h4: {
                color: LightenDarkenColor(primaryColor, 70) + " !important",
            },
            subtitle1: {
                color: LightenDarkenColor(primaryColor, 40) + " !important",
            },

        },
        MuiTablePagination: {
            toolbar: {
                position: 'absolute',
                right: 8,
                bottom: 0,
            },
        },
        MuiTableCell: {
            head: {
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 5,
                paddingRight: 5,
                backgroundColor: '#F5F5F5',
            },
            body: {
                height: 40,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 20,
            }
        },

    }
});
export default theme;
