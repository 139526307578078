import {
    GET_HOUSEHOLD,
    GET_LOCAL_POWER_PRODUCTION,
    SET_HOUSEHOLD,
    SET_LOCAL_POWER_PRODUCTION
} from "./localProductionConstants";
import {LocalPowerProduction} from "../../models/entities/localProduction/localPowerProduction";
import {HouseHold} from "../../models/entities/localProduction/houseHold";


export let LocalProductionActions = {

    getLocalPowerProduction() {
        return {type: GET_LOCAL_POWER_PRODUCTION};
    },
    getHouseHolds() {
        return {type: GET_HOUSEHOLD};
    },
    setLocalPowerProduction(localPowerProduction: LocalPowerProduction | null) {
        return {type: SET_LOCAL_POWER_PRODUCTION, payload: {localPowerProduction}};
    },
    setHouseHolds(houseHolds: HouseHold[] | null) {
        return {type: SET_HOUSEHOLD, payload: {houseHolds}};
    },


}

