export class Question {
    id: number;
    title: string;
    description: string;
    category: string;


    constructor(id: number, title: string, description: string, category: string) {
        this.id = id
        this.title = title
        this.description = description
        this.category = category
    }
}