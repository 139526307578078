import {createSelector} from 'reselect'
import {selectUserParticipants} from "../participants/participantsSelector";
import {selectUploadedDateRangeTo} from "../datas/datasSelector";

const selectBillCheck = (state: any) => state.billCheck;

const selectForm = createSelector(
    selectBillCheck,
    billCheck => billCheck.form
);

const selectPRM = createSelector(
    selectForm,
    form => form.prm
);

const selectParticipantIdFromPRM = createSelector(
    selectPRM,
    selectUserParticipants,
    (prm, participants)=>participants.find((participant) => participant._enedisDetail._prm === prm)?._id.toString()
)
const selectParticipantConsumerTypeFromPRM = createSelector(
    selectPRM,
    selectUserParticipants,
    (prm, participants)=>participants.find((participant) => participant._enedisDetail._prm === prm)?._enedisDetail?._entityType
)
const selectDateStart = createSelector(
    selectForm,
    form => form.dateStart
);

const selectDateEnd = createSelector(
    selectForm,
    form => form.dateEnd
);

const selectConsumerType = createSelector(
    selectForm,
    form => form.consumerType
);
const selectTarif = createSelector(
    selectForm,
    form => form.tarif
);
const selectTarification = createSelector(
    selectTarif,
    tarif => tarif.contractType
);

const selectBasekWhPrice = createSelector(
    selectTarif,
    tarif => tarif.basekWhprice
);

const selectPeakPrice = createSelector(
    selectTarif,
    tarif => tarif.peakkWhPrice
);
const selectOffPeakPrice = createSelector(
    selectTarif,
    tarif => tarif.offPeakkWhPrice
);

const selectPeakWinterPrice = createSelector(
    selectTarif,
    tarif => tarif.winterPeakkWhPrice
);
const selectOffPeakWinterPrice = createSelector(
    selectTarif,
    tarif => tarif.winterOffPeakkWhPrice
);

const selectPeakSummerPrice = createSelector(
    selectTarif,
    tarif => tarif.summerPeakkWhPrice
);
const selectOffPeakSummerPrice = createSelector(
    selectTarif,
    tarif => tarif.summerOffPeakkWhPrice
);


const selectResults = createSelector(
    selectBillCheck,
    billCheck => billCheck.result
);

const selectSelfConsumptionResult = createSelector(
    selectResults,
    result => result.selfConsumed
);
const selectGlobalConsumptionResult = createSelector(
    selectResults,
    result => result.globalConsumption
);
const selectSelfEconomyResult = createSelector(
    selectResults,
    result => result.economy
);
const selectTRV = createSelector(
    selectTarif,
    tarif => tarif.TRV
);
const selectTRVBase = createSelector(
    selectBillCheck,
    billCheck => billCheck && billCheck.TRV && billCheck.TRV.basekWhPrice
);
const selectPeakTRV = createSelector(
    selectBillCheck,
    billCheck => billCheck && billCheck.TRV &&  billCheck.TRV.peakkWhPrice
);
const selectOffPeakTRV = createSelector(
    selectBillCheck,
    billCheck => billCheck && billCheck.TRV &&  billCheck.TRV.offPeakkWhPrice
);

export {
    selectTRVBase,
    selectPeakTRV,
    selectOffPeakTRV,
    selectGlobalConsumptionResult,
    selectPeakWinterPrice,
    selectOffPeakWinterPrice,
    selectPeakSummerPrice,
    selectOffPeakSummerPrice,
    selectPRM,
    selectOffPeakPrice,
    selectPeakPrice,
    selectDateStart,
    selectDateEnd,
    selectConsumerType,
    selectTarification,
    selectBasekWhPrice,
    selectTarif,
    selectSelfEconomyResult,
    selectSelfConsumptionResult,
    selectTRV,
    selectParticipantIdFromPRM,
    selectParticipantConsumerTypeFromPRM
}
