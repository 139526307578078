//MyDatas Actions

import {
    GET_CONSODATA,
    GET_CONSODATA_IS_REAL,
    GET_CURRENTDATE,
    GET_INTERVAL, GET_LATEST_CONSODATA,
    GET_LOCALDATA,
    GET_LOCALDATA_IS_REAL,
    SET_CONSODATA,
    SET_CONSODATA_IS_REAL,
    SET_CURRENTDATE,
    SET_INTERVAL,
    SET_LOCALDATA,
    SET_LOCALDATA_IS_REAL,
    ADD_ONE_TIME_UNIT,
    SUBTRACT_ONE_TIME_UNIT,
    SET_CONSO_DATE_START,
    SET_CONSO_DATE_END, SET_UPLOADED_DATE_RANGE, GET_MEASUREMENT_RANGE
} from "./datasConstants";
import {getMeasurementRangeSaga} from "./datasSaga";


export let DatasActions = {

    setInterval(interval: string) {
        return {type: SET_INTERVAL, payload : {interval}};
    },
    setDateStart(date: string) {
        return {type: SET_CONSO_DATE_START, payload: date};
    },
    setDateEnd(date: string) {
        return {type: SET_CONSO_DATE_END, payload: date};
    },
    getInterval() {
        return {type: GET_INTERVAL};
    },

    setCurrentDate(currentDate: Date | null) {
        return {type: SET_CURRENTDATE,  payload : {currentDate}};
    },

    getCurrentDate() {
        return {type: GET_CURRENTDATE};
    },
    getConsoData(){
        return {type: GET_CONSODATA };
    },
    getLatestConsoData(){
        return {type: GET_LATEST_CONSODATA };
    },
    setConsoData(consoData: any) {
        return {type: SET_CONSODATA, payload : {consoData}};
    },
    getLocalData() {
        return {type: GET_LOCALDATA};
    },
    setLocalData(localData: any) {
        return {type: SET_LOCALDATA, payload:{localData}};
    },
    setConsoDataIsReal(consoDataIsReal:boolean) {
        return {type: SET_CONSODATA_IS_REAL, payload:{consoDataIsReal}};
    },
    getConsoDataIsReal() {
        return {type: GET_CONSODATA_IS_REAL};
    },
    setLocalDataIsReal(localDataIsReal:boolean) {
        return {type: SET_LOCALDATA_IS_REAL, payload:{localDataIsReal}};
    },
    getLocalDataIsReal() {
        return {type: GET_LOCALDATA_IS_REAL};
    },
    addOneTimeUnit() {
        return {type: ADD_ONE_TIME_UNIT};
    },
    subtractOneTimeUnit() {
        return {type: SUBTRACT_ONE_TIME_UNIT};
    },
    setUploadedDateRange(from : Date, to : Date) {
        return {type : SET_UPLOADED_DATE_RANGE, payload : {from : from, to : to}}
    },
    getMeasurementRange(PRM ?:string){
        return {type : GET_MEASUREMENT_RANGE, payload :{PRM}}
    }
}
