import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import queryString from 'query-string';
import {User} from "../../models/entities/authentication/user";
import {authenticationGatewayInterface} from "./authenticationGatewayInterface";
import {AuthenticationActions} from "./authenticationActions";
import {setError} from "../error/errorActions";
import {ACCEPT_CGU, CHANGE_PASSWORD, GET_USER, LOG_IN, LOG_OUT} from "./authenticationConstants";
import {push} from "connected-react-router";
import {ParticipantsActions} from "../participants/participantsActions";


function* getUser(action: any) {
    try {

        yield put(
            AuthenticationActions.setIsAuthenticating(true)
        );
        const authenticationGatewayInterface: authenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        const user: User = yield call(authenticationGatewayInterface.getUser);
        if (!user._hasAcceptedCgu) {
            yield put(
                AuthenticationActions.setCgu({open: true, showAcceptButton: true})
            );
            yield put(push('/login'))
        } else {
            yield put(
                AuthenticationActions.setUser(user),
            );
        }
        yield put(
            AuthenticationActions.setUser(user),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }

    yield put(
        AuthenticationActions.setIsAuthenticating(false)
    );
}

function* logIn(action: any) {
    try {
        // TODO ADD yield Loading à true, et false à la fin de la fonction
        const {history, email, password} = action;
        const authenticationGatewayInterface: authenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGatewayInterface.logIn,  {email, password});
        const user: User = yield call(authenticationGatewayInterface.getUser);

        if (user._hasAcceptedCgu) {
            yield put(
                AuthenticationActions.setUser(user),
            );
            const path = queryString.parse(history.location.search);
            history.push(path.redirect || '/enoconso');
        } else {
            yield put(
                AuthenticationActions.setCgu({open: true, showAcceptButton: true})
            );
        }
        yield put(
            AuthenticationActions.setUser(user),
        );
    } catch (error:any) {
        yield put(
            setError({
                status: error.response && error.response.status,
                message: 'Utilisateur inconnu ou mot de passe incorrect',
                variant: 'warning'
            }),
        );
        console.error(error);
    }
}

function* logOut() {
    try {
        const authenticationGatewayInterface: authenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGatewayInterface.logOut);
        yield put(push('/login'))
        yield put(
            AuthenticationActions.initUser(),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}


function* acceptCGU(action: any) {
    try {
        const {cguId, history} = action;

        const authenticationGatewayInterface: authenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGatewayInterface.acceptCgu, cguId);
        const user: User = yield call(authenticationGatewayInterface.getUser);

        yield put(
            AuthenticationActions.setUser(user),
        );
        yield put(
            AuthenticationActions.setCgu({open: false}),
        );
        yield put(push('/enoconso'))
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}



function* changePassword(action: any) {
    try {
        const { oldPassword, newPassword} = action.payload;
        const authenticationGatewayInterface: authenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGatewayInterface.changePassword, {oldPassword: oldPassword, newPassword : newPassword});
        yield put(
            AuthenticationActions.setInitialized(true)
        )
        yield put(push('/enoconso'))
        // redirect HomePage
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* changePasswordSaga() {

    yield takeLatest(CHANGE_PASSWORD, changePassword)
}

export function* getUserSaga() {
    // console.log("authenticationSaga>*getUserSaga");
    yield takeLatest(GET_USER, getUser);
}

export function* logInSaga() {
    yield takeLatest(LOG_IN, logIn);
}

export function* logOutSaga() {
    yield takeLatest(LOG_OUT, logOut);
}

export function* acceptCGUSaga() {
    // console.log("authenticationSaga>*acceptCGUSaga");
    yield takeLatest(ACCEPT_CGU, acceptCGU);
}


export default [getUserSaga, logInSaga, logOutSaga, acceptCGUSaga, changePasswordSaga];
