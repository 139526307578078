import React from "react";
import {Redirect, Route} from "react-router-dom";
import {APPNAME, participantTypes, roles} from "../../../../../../config/app-config";
import {createStructuredSelector} from "reselect";
import {selectUser} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {connect, useSelector} from "react-redux";
import {User} from "../../../../../../corelogic/models/entities/authentication/user";
import {Authentication} from "../../../../../../corelogic/models/types/Authentication";
import {selectAppmap} from "../../../../../../corelogic/usecases/navigation/navigationSelector";

type TRedirectionRoute = {
    user: User,
    exact?: boolean,
    path: string,
    cProps: any,
    rest?: any[]
}
const RedirectionRoute = ({user, cProps, ...rest}: TRedirectionRoute) => {
    const appmap = useSelector(selectAppmap)

    if (!user || !user._id) {
        window.location.href = appmap.root;
        return <div/>
    }
    return (
        <Route
            {...rest}
            render={props => {
                if (!user._initialized) {
                    return (<Redirect
                        to={`/initAccount?redirect=${props.location.pathname}${props.location.search}`}
                    />);
                } else if (user._role === roles.PARTICIPANT && user._energyType === participantTypes.CONSUMER) {
                    return (<Redirect to={`${APPNAME.route}`}/>);
                } else {
                    return (<Redirect to={`NotFound`}/>);
                }
            }
            }
        />)
}


let mapStateToProps: (state: { authentication: Authentication }) => { user: User };
mapStateToProps = createStructuredSelector(
    {
        user: selectUser,
    },
);


export default connect(mapStateToProps, null)(RedirectionRoute);
