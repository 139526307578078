import {BillingDetail} from "./billingDetail";
import {EnedisDetail} from "./enedisDetail";

export class EParticipant{

    _id:number;
    _name : string;
    _userId : number;
    _operationId:number;
    _billingDetail:BillingDetail;
    _enedisDetail:EnedisDetail;


    constructor(id:number,name:string, userId :number, operationId:number, billingDetail : BillingDetail, enedisDetail : EnedisDetail) {
        this._id = id;
        this._name=name;
        this._userId=userId;
        this._operationId=operationId;
        this._billingDetail = billingDetail;
        this._enedisDetail = enedisDetail;

    }

}
