import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {documentGatewayInterface} from "./documentGatewayInterface";
import {MyDocument} from "../../models/types/Document";
import {DocumentActions} from "./documentActions";
import {DOWNLOAD_FILE, GET_DOCUMENT} from "./documentConstants";
import {selectCurrentParticipantId, selectCurrentParticipantOperationId} from "../participants/participantsSelector";

function* getDocument() {
    try {
        const participantId:number = yield select(selectCurrentParticipantId)
        const operationId: number = yield select(selectCurrentParticipantOperationId)

        const documentGatewayInterface: documentGatewayInterface = yield getContext('documentGatewayContext');
        const document: MyDocument = yield call(documentGatewayInterface.getDocument,participantId, operationId);
        yield put(
            DocumentActions.setFolders(document.folders),
        );
        yield put(
            DocumentActions.setFiles(document.files),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* downloadFile(action: any) {
    try {
        const operationId: number = yield select(selectCurrentParticipantOperationId)
        const { payload } = action
        const { fileId } = payload;

        const documentGatewayInterface: documentGatewayInterface = yield getContext('documentGatewayContext');
        yield call(documentGatewayInterface.downloadFile, fileId, operationId);

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* getDocumentSaga() {
    yield takeLatest(GET_DOCUMENT, getDocument);
}

export function* downloadFileSaga() {
    yield takeLatest(DOWNLOAD_FILE, downloadFile);
}

export default [getDocumentSaga, downloadFileSaga];
