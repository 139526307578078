import client from "../libs/client";
import {navigationGatewayInterface} from "../../../corelogic/usecases/navigation/navigationGatewayInterface";
import {Appmap} from "../../../corelogic/models/entities/navigation/Appmap";

export default class APINavigationGateway implements navigationGatewayInterface {

    getAppmap = async () => {
        const {data: appmap} = await client.get('/appmap');
        return new Appmap(appmap.enoconso, appmap.enoapp, appmap.root);
    };

}
