import {createSelector} from "reselect";
import {Navigation} from "../../models/types/Navigation";


const selectNavigation = (state: { navigation: Navigation; }) => state.navigation;


const selectAppmap = createSelector(
    selectNavigation,
    navigation => navigation.appmap
)


export {selectAppmap}