import {Weather} from "./weather";

export class DailyWeather {
    private _date: string;
    private _weather : Weather[];

    constructor(date: string, weather : Weather[]) {
        this._date = date;
        this._weather = weather;
    }

    set date(value: string) {
        this._date = value;
    }

    set weather(value: Weather[]) {
        this._weather = value;
    }

    get date(): string {
        return this._date;
    }

    get weather(): Weather[] {
        return this._weather;
    }
}