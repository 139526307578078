//operation reducer
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const SET_OPERATIONS = 'SET_OPERATIONS';
export const SET_SELECTED_OPERATION = 'SET_SELECTED_OPERATION';
export const INIT_SELECTED_OPERATION = 'INIT_SELECTED_OPERATION';
export const INIT_OPERATIONS = 'INIT_OPERATIONS';
export const GET_OPERATION_PARTICIPANTS = 'GET_OPERATION_PARTICIPANTS';
export const UPDATE_OPERATION = 'UPDATE_OPERATION';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORTING = 'SET_SORTING';
export const INIT_SORTING = 'INIT_SORTING';
export const SET_PARTICIPANT_TYPE = 'SET_PARTICIPANT_TYPE';
export const UPDATE_PRESIGNED_LOGOS = 'UPDATE_PRESIGNED_LOGOS';


export const GET_PARTICIPANT_OPERATION = 'GET_PARTICIPANT_OPERATION';
export const SET_PARTICIPANT_OPERATION = 'SET_PARTICIPANT_OPERATION';

// My Operation
export const SET_OPERATION_INFOS = 'SET_OPERATION_INFOS';
export const GET_OPERATION_INFOS = 'GET_OPERATION_INFOS';

export const SET_LATEST_DATE_WITH_DATA = 'SET_LATEST_DATE_WITH_DATA';
export const GET_LATEST_DATE_WITH_DATA = 'GET_LATEST_DATE_WITH_DATA';
