export class EOperationNumbers {

    _consumers: number;
    _producers: number;
    _powerExchanged: number

    constructor(consumers: number, producers: number, powerExchanged: number) {
        this._powerExchanged = powerExchanged
        this._consumers = consumers
        this._producers = producers
    }

    serialize() {
        return {
            consumers: this._consumers,
            producers: this._producers,
            powerExchanged: this._powerExchanged
        }
    }

    get consumers() {
        return this._consumers
    }

    get producers() {
        return this._producers
    }

    get powerExchanged() {
        return this._powerExchanged
    }

}
