import {
    SET_APPMAP,
} from './navigationConstants';
import {combineReducers} from "redux";
import {ActionWithPayload} from "../../redux/createAction";
import {Appmap} from "../../models/entities/navigation/Appmap";


type TAppmapReducer = { appmap: Appmap, initialized: boolean }

function appmapReducer(state = {}, action: ActionWithPayload<TAppmapReducer>) {
    switch (action.type) {
        case SET_APPMAP: {
            return action.payload.appmap;
        }
        default:
            return state;
    }
}


export default combineReducers({
    appmap: appmapReducer,
});