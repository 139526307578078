import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import error from "../../../media/Images/error-image.svg"
import MuiTheme from "../../../../../../config/MuiTheme";

const enoTheme = MuiTheme();

const useStyles = makeStyles(theme => ({
    main:{
        display:"flex",
        height : "100%",
        width : "min(100%,600px)",
        flexDirection:"column",
        justifyContent:"center",
        textAlign:"center",
    },
    title : {
        flexWrap : "wrap",

        textTransform : "uppercase",
        color: enoTheme.palette.primary.main,
        fontWeight : "bold",
        fontSize : "min(12vmin,45px)",
    },
    description : {
        color: "#8c8c8c", // resolve : enoTheme.palette.blackToGrey.main,
        fontSize : "min(4vmin,15px)",
        fontWeight : "normal",
    },
    image : {
        paddingTop : "8vh",
        paddingBottom : "8vh",
        alignSelf : "center",
        width : "min(80vmin, 600px)",
    }
}));


export default function GlobalErrorsPage() {
    const classes = useStyles();
    return (
        <div className={classes.main}>
                <span className = {classes.title}> Un problème est survenu </span>
                <img className = {classes.image}src={error} width="60%"  alt="ErrorImage"/>
                <Typography className = {classes.description} variant="h5" component="h5"> Nous n’avons pas réussi à afficher correctement cette page.
                    Vous pouvez rafraichir la page et ré-essayer. </Typography>
        </div>
    );
}