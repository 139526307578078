import React, {Component} from "react";
import SignIn from "../../../Components/global/authentication/SignIn";
import {createStructuredSelector} from "reselect";
import {selectUser} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import {Authentication} from "../../../../../../corelogic/models/types/Authentication";
import {User} from "../../../../../../corelogic/models/entities/authentication/user";
import {compose} from "redux";
import {AuthenticationActions} from "../../../../../../corelogic/usecases/authentication/authenticationActions";
import {History, LocationState} from 'history';

/** Good Manner : Type instead of interface*/

type TProps = { logIn: typeof AuthenticationActions.logIn, history : History<LocationState> }
type TState = { username: string, password: string }


 class Login extends Component<TProps, TState> {

    /** Good Manner : No Use Of Constructor*/
    state = {
        username: "",
        password: ""
    }
    /*constructor(props: MyProps) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    }*/

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChange = (event: { target: { id: number; value: number; }; }) => {
        this.setState({
            password: this.state.password, username: this.state.username,
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async (event: { preventDefault: () => void; target: { username: { value: string; }; password: { value: string; }; }; }) => {
        event.preventDefault();
        const {logIn, history} = this.props;
        logIn({history, email: event.target.username.value, password: event.target.password.value});
    };


    render() {
        return (
            <div className="Login">
                <SignIn onSubmit={this.handleSubmit}/>
            </div>
        );
    }
}


let mapStateToProps: (state: { authentication: Authentication }) => { user: User };

mapStateToProps = createStructuredSelector(
    {
        user: selectUser,
    },
);

const mapDispatchToProps = {
    setUser: AuthenticationActions.setUser,
    setCgu: AuthenticationActions.setCgu,
    logIn: AuthenticationActions.logIn,
};

/** Good Manner : enhance*/
const enhance = compose(connect(mapStateToProps, mapDispatchToProps),withSnackbar);
export default enhance(Login);
