import {consumptionGatewayInterface} from "../../../corelogic/usecases/consumption/consumptionGatewayInterface";
import {ECO_MONEY, ECO_NATURE, WEATHER_CLOUDY, WEATHER_RAINY, WEATHER_SUNNY} from "../../../config/app-config";
import {Ecoconseil} from "../../../corelogic/models/entities/consumption/ecoconseil";
import {DailyWeather} from "../../../corelogic/models/entities/consumption/dailyWeather";
import {Coordinates} from "../../../corelogic/models/entities/consumption/coordinates";
import {Weather} from "../../../corelogic/models/entities/consumption/weather";

export default class InMemoryConsumptionGateway implements consumptionGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };


    getWeather(operationId: number, city: string, coordinates: Coordinates): DailyWeather[] {
        return [
            new DailyWeather("Aujourd'hui", [
                new Weather(WEATHER_CLOUDY, "Grenoble", "Matinée", false),
                new Weather(WEATHER_SUNNY, "Grenoble", "Journée", true),
                new Weather(WEATHER_RAINY, "Grenoble", "Soirée", true)
            ]),
            new DailyWeather("Demain", [
                new Weather(WEATHER_CLOUDY,  "Grenoble",  "Matinée",  true),
                new Weather( WEATHER_SUNNY,  "Grenoble",  "Journée",  true),
                new Weather(WEATHER_CLOUDY, "Grenoble", "Soirée", true)
            ])
        ]
    }

    getEcoConseils(): Ecoconseil[] {
        return [
            new Ecoconseil(
                ECO_NATURE,
                "Faire étinceller ma vaisselle",
                "Utiliser le ballon d’eau chaude durant les périodes les plus ensoleillées.",
                "Faible émission de CO2",
            ),
            new Ecoconseil(
                ECO_MONEY,
                "Je régule mon ECS",
                "La température idéale de mon ECS est de 50°C.",
                "50€ par an à 50° au lieu de 60°",
            ),
            new Ecoconseil(
                ECO_MONEY,
                "J’analyse mon talon",
                "Le talon de consommation est la part d’énergie consommée lorsque tout les appareils sont éteints. ",
                "représente 10 à 15% d’un bâti",
            ),
        ]
    }

    getProductionTypes(operationId: number): string[] {
        return ["SOLAIRE - PHOTOVOLTAIQUE"];
    }

    getCoordinates(city: string, postCode: string): Coordinates {
        return new Coordinates('5', '6')
    }
}
