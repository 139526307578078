import {ActionWithPayload} from "../../redux/createAction";
import {SET_COORDINATES, SET_ECOCONSEILS, SET_PRODUCTION_TYPES, SET_WEATHER} from "./consumptionConstants";

const initialState = {
    weathers: null,
    ecoconseils: null,
    typesOfProduction: [],
    coordinates: null
};


export default function consumptionReducer(state = initialState, action: ActionWithPayload<any>) {
    switch (action.type) {
        case SET_WEATHER: {
            return {...state, weathers: action.payload.weathers};
        }
        case SET_ECOCONSEILS: {
            return {...state, ecoconseils: action.payload.ecoconseils};
        }
        case SET_PRODUCTION_TYPES: {
            return {...state, typesOfProduction: action.payload.typesOfProduction};
        }
        case SET_COORDINATES : {
            return {
                ...state, coordinates: action.payload.coordinates
            }
        }
        default:
            return state;
    }
}

