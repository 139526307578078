import {helpGatewayInterface} from "../../../corelogic/usecases/help/helpGatewayInterface";
import client from "../libs/client";
import {Question} from "../../../corelogic/models/entities/help/question";

export default class APIHelpGateway implements helpGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };

    async getQuestions(operationId: number): Promise<Question[]> {
        const {data} = await client.get(`/operations/${operationId}/questions`)
        return data.map((q: any) => new Question(q.id, q.title, q.description, q.category))
    }
}




