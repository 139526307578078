export const ACCEPTED = "ACCEPTED"
export const REJECTED = "REJECTED"
export const NULL = "NULL"

export enum Status {NULL="NULL", ACCEPTED="ACCEPTED", REJECTED="REJECTED"}
type Recipient = { email :string, status : Status }[]

export class Message{
    content:string
    recipient : Recipient

    constructor(  content : string, recipient:Recipient) {
        this.content=content
        this.recipient=recipient
    }
}