import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {selectUserParticipants} from "../participants/participantsSelector";
import {BillCheckActions, GET_PRICE_CONFIG, GET_RESULTS, GET_RESULTS_SELF_CONSUMED, GET_TRV} from "./billCheckActions";
import {EParticipant} from "../../models/entities/participant/participant";
import {billCheckGatewayInterface} from "./billCheckGatewayInterface";
import {
    selectConsumerType,
    selectDateEnd,
    selectDateStart,
    selectParticipantConsumerTypeFromPRM,
    selectParticipantIdFromPRM,
    selectPRM,
    selectTarif
} from "./billCheckSelector";

function* getPriceConfig() {
    try {
        const participantId: string | undefined = yield select(selectParticipantIdFromPRM)
        const billCheckGateway: billCheckGatewayInterface = yield getContext('billCheckGatewayContext');
        // @ts-ignore
        const config = yield call(billCheckGateway.getPriceConfig, participantId)
        if (config) {
            const {
                TRV, contractType, consumerType, basekWhPrice, peakkWhPrice, offPeakkWhPrice, summerPeakkWhPrice,
                summerOffPeakkWhPrice, winterPeakkWhPrice, winterOffPeakkWhPrice, peakStart, offPeakStart
            } = config;
            yield put(BillCheckActions.setContractType(contractType));
            yield put(BillCheckActions.setHasTRV(TRV));
            yield put(BillCheckActions.setConsumerType(consumerType));
            if (contractType !== 'base') {
                yield put(BillCheckActions.setPeakStart(peakStart));
                yield put(BillCheckActions.setOffPeakStart(offPeakStart));
            }
            if (!TRV) {
                if (contractType === 'base') {
                    yield put(BillCheckActions.setBasekWhPrice(basekWhPrice));
                }
                if (contractType === 'double_price') {
                    yield put(BillCheckActions.setPeakPrice(peakkWhPrice));
                    yield put(BillCheckActions.setOffPeakPrice(offPeakkWhPrice));
                }
                if (contractType === 'quadruple_price') {
                    yield put(BillCheckActions.setPeakSummerPrice(summerPeakkWhPrice));
                    yield put(BillCheckActions.setOffPeakSummerPrice(summerOffPeakkWhPrice));
                    yield put(BillCheckActions.setPeakWinterPrice(winterPeakkWhPrice));
                    yield put(BillCheckActions.setOffPeakWinterPrice(winterOffPeakkWhPrice));

                }
            }
        }
        else {
            const participantConsumerType:string | null = yield select(selectParticipantConsumerTypeFromPRM)
            if(participantConsumerType){
                yield put(BillCheckActions.setConsumerType(participantConsumerType.startsWith('Professionnel') ?"professional" : "individual"))
            }

        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getSelfConsumedMetrics() {
    try {
        const participants: EParticipant[] = yield select(selectUserParticipants)
        const startDate: Date = yield select(selectDateStart)
        const endDate: Date = yield select(selectDateEnd)
        const prm: string = yield select(selectPRM)
        const participantId: string | undefined = participants.find((participant) => participant._enedisDetail._prm === prm)?._id.toString()
        const billCheckGatewayInterface: billCheckGatewayInterface = yield getContext('billCheckGatewayContext');
        if (participantId) {
            const selfConsumedData: { selfConsumed: string, globalConsumption: string } = yield call(
                billCheckGatewayInterface.getSelfConsumedMetrics,
                participantId,
                new Date(startDate),
                new Date(endDate)
            );
            yield put(
                BillCheckActions.setSelfConsumedMetrics(selfConsumedData.selfConsumed)
            );
            yield put(
                BillCheckActions.setGlobalConsumptionMetric(selfConsumedData.globalConsumption)
            )
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getResults() {
    try {
        const {
            peakkWhPrice,
            offPeakkWhPrice,
            summerOffPeakkWhPrice,
            summerPeakkWhPrice,
            winterOffPeakkWhPrice,
            winterPeakkWhPrice,
            offPeakStart,
            peakStart,
            contractType,
            basekWhprice,
            TRV
        } = yield select(selectTarif)

        const startDate:Date = yield select(selectDateStart)
        const endDate:Date = yield select(selectDateEnd)
        const consumerType:string = yield select(selectConsumerType)
        const participantId: string | undefined = yield select(selectParticipantIdFromPRM)

        const billCheckGatewayInterface: billCheckGatewayInterface = yield getContext('billCheckGatewayContext');

        if (participantId) {
            const billCheckResults: string[] = yield call(
                billCheckGatewayInterface.getResults,
                participantId,
                startDate.toISOString(),
                endDate.toISOString(),
                consumerType,
                contractType,
                basekWhprice,
                peakkWhPrice,
                offPeakkWhPrice,
                summerOffPeakkWhPrice,
                summerPeakkWhPrice,
                winterOffPeakkWhPrice,
                winterPeakkWhPrice,
                offPeakStart,
                peakStart,
                TRV
            );
            yield put(
                BillCheckActions.setResults(billCheckResults),
            );
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getTRV() {
    try {
        const billCheckGatewayInterface: billCheckGatewayInterface = yield getContext('billCheckGatewayContext');
        const TRVs:  {id:string,basekWhPrice:number,peakkWhPrice:number,offPeakkWhPrice:number,periodStart:string} = yield call(billCheckGatewayInterface.getTRVs);
        console.log(TRVs)
        yield put(
            BillCheckActions.setTRV(TRVs),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* getTRVSaga() {
    yield takeLatest(GET_TRV, getTRV)
}


export function* getResultsSaga() {
    yield takeLatest(GET_RESULTS, getResults);
}

export function* getSelfConsumedMetricsSaga() {
    yield takeLatest(GET_RESULTS_SELF_CONSUMED, getSelfConsumedMetrics);
}

export function* getPriceConfigSaga() {
    yield takeLatest(GET_PRICE_CONFIG, getPriceConfig);
}

export default [getTRVSaga, getResultsSaga, getSelfConsumedMetricsSaga, getPriceConfigSaga];
