import {MyLocalProduction} from "../../models/types/LocalProduction";
import {SET_HOUSEHOLD, SET_LOCAL_POWER_PRODUCTION} from "./localProductionConstants";
import {ActionWithPayload} from "../../redux/createAction";


const initialState = {
    houseHolds : null,
    localPowerProduction : null
};


export default function documentReducer(state = initialState, action: ActionWithPayload<MyLocalProduction>) {
    switch (action.type) {
        case SET_LOCAL_POWER_PRODUCTION: {
            return {...state, localPowerProduction : action.payload.localPowerProduction};
        }
        case SET_HOUSEHOLD: {
            return {...state, houseHolds : action.payload.houseHolds};
        }
        default:
            return state;
    }
}

