import React, {useState, useEffect} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import client from '../../../../../secondary/libs/client';
import axios from 'axios';
import {ProviderContext, useSnackbar} from 'notistack';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Paper,
    withStyles
} from "@material-ui/core";
import DialogTitle from './DialogTitle'
import {compose} from 'redux';
import {ComponentType} from 'react';
import PopupTransition from './PopupTransition';
import {ChevronLeft, ChevronRight, GetApp} from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const styles = (theme: any) => createStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 800,
        minHeight: 600,
        height: '80%',
    },
    container: {
        paddingTop: 100,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fafafa',
    },
    dialogActionsRoot: {
        justifyContent: 'space-between',
    },
    downloadButtonRoot: {
        '&:hover': {
            background: 'none',
            color: theme.palette.primary.main,
        }
    },
    iconButton: {
        width: 40,
        height: 40,
    },
});


type CGUPopup2Props = {
    handleCloseCGU?: () => void,
    cguPopup: { open: any, showAcceptButton: boolean },
    acceptCGU?: any,
    classes: any, // withStyles props
} & ProviderContext

const CGUPopup = ({handleCloseCGU, cguPopup, acceptCGU, classes}: CGUPopup2Props) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [cguPdf, setCguPdf] = useState(false);
    const [cgu, setCgu] = useState({url: "", id: undefined})
    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {
        if (cguPopup.open && !cguPdf) {
            getPDF()
        }
    }, [])

    useEffect(() => {
        setButtonDisabled(true)
        setPageNumber(1)
    }, [cguPopup.open])

    useEffect(() => {
        getPDF()
    }, [cguPopup.open, !cguPdf])

    useEffect(() => {
        setButtonDisabled(pageNumber !== numPages)
    }, [pageNumber || numPages])

    async function getPDF() {
        try {
            const {data: cgu} = await client.get(`/cgu/last-cgu`);
            const {data: pdf} = await axios(cgu.url, {
                method: 'GET',
                responseType: 'blob',
            });
            setCgu(cgu)
            setCguPdf(cgu.url !== "")
        } catch (e) {
            enqueueSnackbar("Une erreur s'est produite. Veuillez réessayer", {
                autoHideDuration: 7000,
                variant: 'error'
            });
        }
    };

    function onDocumentLoadSuccess({numPages}: { numPages: number }) {
        setNumPages(numPages);
    }

    const downloadCgu = () => {
        if (cgu) {
            window.location.href = cgu.url;
        }
    };

    return (
        <Dialog
            fullScreen
            open={cguPopup.open}
            onClose={handleCloseCGU}
            TransitionComponent={PopupTransition}
        >
            <div className={classes.container}>
                <Paper
                    className={classes.paper}>
                    <DialogTitle onClose={handleCloseCGU}
                                 id="alert-dialog-slide-title">
                        {"Conditions générales d’utilisation"}
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-slide-description">
                            {cguPdf &&
                            <div>
                                <Document
                                file={cgu.url} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber}/>
                                </Document>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : setPageNumber(1)}
                                    >
                                        <ChevronLeft/>
                                    </IconButton>
                                    {pageNumber} / {numPages}
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : setPageNumber(1)}
                                    >
                                        <ChevronRight/>
                                    </IconButton>
                                </div>
                            </div>
                            }

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions classes={{root: classes.dialogActionsRoot}}>
                        <Button
                            classes={{root: classes.downloadButtonRoot}}
                            startIcon={<GetApp/>}
                            onClick={downloadCgu}
                        >
                            Télécharger PDF
                        </Button>
                        {cguPopup.showAcceptButton &&
                        <Button
                            style={{marginLeft: 8}}
                            disabled={buttonDisabled}
                            variant="contained"
                            onClick={() => acceptCGU(cgu.id)}
                            color="primary">
                            Accepter
                        </Button>
                        }
                    </DialogActions>
                </Paper>

            </div>
        </Dialog>
    );
}

export default compose(withStyles(styles)(CGUPopup)) as ComponentType <any>;
