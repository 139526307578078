export class User{

    _id:number;
    _role:string;
    _email : string;
    _initialized: boolean;
    _hasAcceptedCgu:boolean;
    _energyType:any;

    constructor(id:number, role:string,  email : string, initialized: boolean, hasAcceptedCgu:boolean, energyType:any) {
        this._id = id;
        this._role = role;
        this._email = email;
        this._initialized = initialized;
        this._hasAcceptedCgu = hasAcceptedCgu;
        this._energyType = energyType;
    }

    serialize() {
        return {
            id:this._id,
            role : this._role,
            email : this._email,
            initialized : this._initialized,
            hasAcceptedCgu : this._hasAcceptedCgu,
            energyType : this._energyType,
        }
    }
}
