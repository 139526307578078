import {ActionWithPayload} from "../../redux/createAction";
import {
    SET_OPERATION_INFORMATION,
} from "./informationConstants";

const initialState = {
    operation: []
};


export default function informationReducer(state = initialState, action: ActionWithPayload<any>) {
    switch (action.type) {
        case SET_OPERATION_INFORMATION: {
            return {...state, operation: action.payload.operation};
        }
        default:
            return state;
    }
}

