import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./rootReducer";
import rootSaga from './rootSaga';
import {routerMiddleware} from 'connected-react-router';
import {
    History,
    LocationState
} from 'history';

interface configureStoreProps {
    history: History<LocationState>,
    sagaContext: any,
}

export default function configureStore({history, sagaContext}:configureStoreProps) {
    const sagaMiddleware = createSagaMiddleware({context : sagaContext});
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);
    const store = createStore(rootReducer(history), composedEnhancers);
    sagaMiddleware.run(rootSaga());
    return store
}
