import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {navigationGatewayInterface} from "./navigationGatewayInterface";
import {NavigationActions} from "./navigationActions";
import {setError} from "../error/errorActions";
import {GET_APPMAP} from "./navigationConstants";
import {Appmap} from "../../models/entities/navigation/Appmap";


function* getAppmap(action: any) {
    try {
        const navigationGatewayInterface: navigationGatewayInterface = yield getContext('navigationGatewayContext');
        const appmap: Appmap = yield call(navigationGatewayInterface.getAppmap);
        yield put(
            NavigationActions.setAppmap(appmap)
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
    }
}


export function* getAppmapSaga() {
    yield takeLatest(GET_APPMAP, getAppmap);
}

export default [getAppmapSaga];
