import {createStyles, Theme, withStyles} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const styles = (theme:Theme) => createStyles({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

type TProps = {
    [x: string]: any;
    children: any;
    onClose: any;
    classes: any;
}
const DialogTitle = ((props:TProps) => {
    const {children, onClose, classes, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const enhance = withStyles(styles);
export default enhance(DialogTitle);

