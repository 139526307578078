import {ActionWithPayload} from "../../redux/createAction";
import {SET_QUESTIONS} from "./helpConstants";


const initialState = {
    recurrentQuestions: []
};


export default function documentReducer(state = initialState, action: ActionWithPayload<any>) {
    switch (action.type) {
        case SET_QUESTIONS: {
            return {
                recurrentQuestions: action.payload.recurrentQuestions,
            }
        }
        default:
            return state;
    }
}

