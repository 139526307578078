import {
    DOWNLOAD_BILL,
    GET_BILLING_STATUS,
    GET_BILLS_HISTORY,
    SET_BILLING_STATUS,
    SET_BILLS_HISTORY
} from "./billingConstants";
import {Billing} from "../../models/types/Billing";
import {BillHistory} from "../../models/entities/billing/billHistory";

export let BillingActions = {

    getBillingStatus() {
        return {type: GET_BILLING_STATUS};
    },
    setBillingStatus(billingStatus: Billing["billingStatus"]) {
        return {type: SET_BILLING_STATUS, payload: {billingStatus}};
    },
    getBillsHistory() {
        return {type : GET_BILLS_HISTORY};
    },
    setBillsHistory(billsHistory: BillHistory[]) {
        return {type: SET_BILLS_HISTORY, payload: {billsHistory}};
    },
    downloadBill(billingPeriodEnd :string){
        return {type : DOWNLOAD_BILL, payload : {billingPeriodEnd}}
    }
}

