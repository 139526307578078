// Date format : 2021-15-02T22:00:00.000Z YYYY-DD-MMThh:mm:sssZ

 function getYear(date:string){
    return date.substring(0,4)
}
 function getMonth(date:string){
    return date.substring(5,7)
}
 function getDay(date:string){
    return date.substring(8,10)
}
 function getHour(date:string){
    return date.substring(11,13)
}
 function getMinute(date:string){
    return date.substring(14,15)
}
 function getSecond(date:string){
    return date.substring(16,19)
}

 function getFullDate(date:string, option?:string){
    if(option != null){
        return getDay(date) +option+ getMonth(date) +option+ getYear(date)
    }
    else {
        return getDay(date) +'-'+ getMonth(date) +'-'+ getYear(date)
    }
}

export {getFullDate, getDay, getHour, getMinute, getMonth ,getSecond, getYear}