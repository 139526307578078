import {
    GET_PARTICIPANTS, SET_BILLING_DETAIL,
    SET_CURRENT_PARTICIPANT,
    SET_ENEDIS_DETAIL,
    SET_PARTICIPANTS, UPDATE_CURRENT_PARTICIPANT_BILLING_DETAIL,
    UPDATE_CURRENT_PARTICIPANT_ENEDIS_DETAIL,
} from "./participantsConstants";
import {EParticipant} from "../../models/entities/participant/participant";
import {EnedisDetail} from "../../models/entities/participant/enedisDetail";
import {BillingDetail} from "../../models/entities/participant/billingDetail";

export let ParticipantsActions = {

    updateCurrentParticipantEnedisDetail(enedisDetail : EnedisDetail | null) {
        return {type : UPDATE_CURRENT_PARTICIPANT_ENEDIS_DETAIL, payload:{enedisDetail}}
    },
    updateCurrentParticipantBillingDetail(billingDetail : BillingDetail | null){
        return {type : UPDATE_CURRENT_PARTICIPANT_BILLING_DETAIL, payload:{billingDetail}}
    },
    getParticipants() {
        return {type: GET_PARTICIPANTS};
    },
    setParticipants(participants: EParticipant[] | null) {
        return {type: SET_PARTICIPANTS, payload: {participants}};
    },
    setCurrentParticipant(currentParticipant: EParticipant | null) {
        return {type: SET_CURRENT_PARTICIPANT, payload: {currentParticipant}};
    },
    setEnedisDetail(enedisDetailUpdated : EnedisDetail |null) {
        return{type: SET_ENEDIS_DETAIL, payload: {enedisDetailUpdated}};
    },
    setBillingDetail(billingDetailUpdated : BillingDetail |null) {
        return{type: SET_BILLING_DETAIL, payload: {billingDetailUpdated}};
    },

}

