import {billCheckGatewayInterface} from "../../../corelogic/usecases/bill-check/billCheckGatewayInterface";

export default class InMemoryBillCheckGateway implements billCheckGatewayInterface {
    getResults(participantId: string, startDate: string, endDate:string, consumerType: string,
               contractType: string, basekWhprice: string, peakkWhPrice: string, offPeakkWhPrice: string,
               summerOffPeakkWhPrice: string, summerPeakkWhPrice: string, winterOffPeakkWhPrice: string,
               winterPeakkWhPrice: string, offPeakStart: string, peakStart: string, TRV:boolean): any {
        return '32'
    }

    getSelfConsumedMetrics(participantId: string, startDate: Date, endDate: Date): any {
        return '47'
    }

    getTRVs(): any {
        return {id:"8",basekWhPrice:0.1,peakkWhPrice:0.07,offPeakkWhPrice:0.08,periodStart:"2022-11-01"}
    }

    getPriceConfig(participantId :string): any {
        return null;
    }

}
