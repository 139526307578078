import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {GET_HOUSEHOLD, GET_LOCAL_POWER_PRODUCTION} from "./localProductionConstants";
import {HouseHold} from "../../models/entities/localProduction/houseHold";
import {LocalProductionActions} from "./localProductionActions";
import {LocalPowerProduction} from "../../models/entities/localProduction/localPowerProduction";
import {localProductionGatewayInterface} from "./localProductionGatewayInterface";
import {selectCurrentParticipant, selectCurrentParticipantId} from "../participants/participantsSelector";
import {Simulate} from "react-dom/test-utils";

function* getHouseHold(action: any) {
    try {
        const participantId:number = yield select(selectCurrentParticipantId)
        const localProductionGatewayInterface: localProductionGatewayInterface = yield getContext('localProductionGatewayContext');
        const houseHolds: HouseHold[] = yield call(localProductionGatewayInterface.getHouseHolds,participantId );
        yield put(
            LocalProductionActions.setHouseHolds(houseHolds),
        );

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getLocalPowerProduction() {
    try {
        const participantId:number = yield select(selectCurrentParticipantId)
        const localProductionGatewayInterface: localProductionGatewayInterface = yield getContext('localProductionGatewayContext');
        const localPowerProduction: LocalPowerProduction = yield call(localProductionGatewayInterface.getLocalPowerProduction,participantId );
        yield put(
            LocalProductionActions.setLocalPowerProduction(localPowerProduction),
        );

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* getHouseHoldSaga() {
    yield takeLatest(GET_HOUSEHOLD, getHouseHold);
}
export function* getLocalPowerProductionSaga() {
    yield takeLatest(GET_LOCAL_POWER_PRODUCTION, getLocalPowerProduction);
}

export default [getHouseHoldSaga, getLocalPowerProductionSaga];
