import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {createStructuredSelector} from "reselect";
import {selectUser} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {connect, useSelector} from "react-redux";
import {User} from "../../../../../../corelogic/models/entities/authentication/user";
import {Authentication} from "../../../../../../corelogic/models/types/Authentication";
import {selectAppmap} from "../../../../../../corelogic/usecases/navigation/navigationSelector";

interface TAuthenticatedRoute {
    path: string,
    exact?: boolean,
    Component: any,
    cProps: any,
    type: string,
    user: User
}

const AuthenticatedRoute = ({Component, user, type, ...rest}: TAuthenticatedRoute) => {
    const appmap = useSelector(selectAppmap)

    if (!user || !user._id) {
        window.location.href = appmap.root;
        return <div/>
    }
    return (
        <Switch>
            <Route
                {...rest}
                render={props => <Component type={type} {...props} />}
            />
        </Switch>);
}

let mapStateToProps: (state: { authentication: Authentication }) => { user: User };
mapStateToProps = createStructuredSelector(
    {
        user: selectUser,
    },
);


export default connect(mapStateToProps, null)(AuthenticatedRoute);
