import {operationGatewayInterface} from "../../../corelogic/usecases/operation/operationGatewayInterface";
import {defaultTheme} from "../../../config/app-config";

export default class InMemoryOperationGateway implements operationGatewayInterface {
    error: any;

    constructor() {
    };

    setError = (error: boolean) => {
        this.error = error;
    };


    getOrganizerByJwt = () => {
        return {
            id: 1,
            name: 'test',
            userId: 1,
        };
    };

    getOperations = (ids: any) => {
        return [
            {
                id: ids,
            }
        ]
    };
    getObserverOperations = () => {
        return [
            {
                id: 1,
            }
        ]
    };

    getOrganizerOperations = (organizerId: any) => {
        return [
            {
                id: 1,
            }
        ]
    };

    getParticipantsByJwt(): any[] {
        return [{
            id: 1,
            name: 'test',
            userId: 1,
            operationId: 1
        }];
    }

    getOperationParticipants = (operationId: number) => {
        return [
            {
                _id: 1,
                _name: 'participant1',
                _userId: 1,
                _operationId: operationId
            },
            {
                _id: 2,
                _name: 'participant2',
                _userId: 1,
                _operationId: operationId
            },
            {
                _id: 3,
                _name: 'participant3',
                _userId: 1,
                _operationId: operationId
            }];
    };

    getOperationObservers = (operationId: number) => {
        return [{
            id: 1,
            name: 'observer name',
            userId: 1,
            operationId: operationId
        }];
    };

    getPresignedLogo(operation: any): any {
    }

    updateOperation(operation: any): any {
    }

    uploadLogo(operation: any, formData: any): any {
    }


    getOperationInfos(operationId: number, latestDayWithDataAvailable : string): Promise<{ consumers: number, producers: number, powerExchanged: number }> {
        switch (operationId) {
            case 1 :
                return Promise.resolve({
                    consumers: 8,
                    producers: 13,
                    powerExchanged: 687
                });
            default:
                return Promise.resolve({
                    consumers: 4,
                    producers: 1,
                    powerExchanged: 89
                })
        }
    }

    getParticipantOperation(operationId: number): Promise<any> {
        return Promise.resolve(
            {
                id: 1,
                enedisId: "ACC00000005",
                name: "Malaunay",
                theme: defaultTheme
            }
        );
    }

    getLatestDateWithData(operationId: number): any {
        return {
            latestDay : '30/06/2022'
        }
    }

}
