import {datasGatewayInterface} from "../../../corelogic/usecases/datas/datasGatewayInterface";
import {ELocalData} from "../../../corelogic/models/entities/datas/localData";
import {EConsoData} from "../../../corelogic/models/entities/datas/consoData";

export default class InMemoryDatasGateway implements datasGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };


    getLocalProductionByInterval(participantId: number, interval: string, from: Date, to: Date): ELocalData[] {
        let data
        switch (interval) {
            case "jour" :
                data = [{_name: "Ma part", _value: 30.15456}, {_name: "Part des autres", _value: 200.15456}, {
                    _name: "Part rejetée",
                    _value: 100.7894564
                },];
                break;
            case "mois" :
                data = [{_name: "Ma part", _value: 10}, {_name: "Part des autres", _value: 500}, {
                    _name: "Part rejetée",
                    _value: 200
                },];
                break;
            default :
                data = [{_name: "Ma part", _value: 60}, {_name: "Part des autres", _value: 100}, {
                    _name: "Part rejetée",
                    _value: 800
                },];
                break;
        }
        return data
    }

    getConso(participantId: number, interval?: string, from ?: Date, to?: Date): EConsoData[] {
        let data: EConsoData[]
        switch (interval) {
            case "jour" :
                data = [
                    new EConsoData("2021-10-10T00:01:00.000Z", 15.12315154, 10.77778),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                    new EConsoData("2021-10-10T00:01:00.000Z", 15, 10),
                ];
                break;
            case "mois" :
                data = [
                    new EConsoData("2021-10-01T00:01:00.000Z",5,2),
                    new EConsoData("2021-12-02T00:01:00.000Z",15,3),
                    new EConsoData("2021-10-03T00:01:00.000Z",15,5),
                    new EConsoData("2021-10-04T00:01:00.000Z",6,10),
                    new EConsoData("2021-10-05T00:01:00.000Z",4,10),
                    new EConsoData("2021-10-06T00:01:00.000Z",6,10),
                    new EConsoData("2021-10-07T00:01:00.000Z",2,10),
                    new EConsoData("2021-10-08T00:01:00.000Z",20,10),
                    new EConsoData("2021-10-09T00:01:00.000Z",12,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",11,10),
                    new EConsoData("2021-10-11T00:01:00.000Z",4,10),
                    new EConsoData("2021-10-12T00:01:00.000Z",6,10),
                    new EConsoData("2021-10-13T00:01:00.000Z",4,10),
                    new EConsoData("2021-10-14T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-15T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-16T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-17T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-18T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-19T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-20T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-21T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-22T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-23T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-24T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-25T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-26T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-27T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-28T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-29T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-30T00:01:00.000Z",15,10),
                ];
                break;
            case "année" :
                data = [
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),

                ];
                break;
            default :
                data = [
                    new EConsoData("2021-10-10T00:01:00.000Z",15,10),
                    new EConsoData("2021-11-10T00:02:00.000Z",15,10),
                    new EConsoData("2021-10-12T00:03:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:04:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:05:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:06:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:07:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:08:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:09:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:10:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:11:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:12:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:13:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:14:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:15:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:16:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:17:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:18:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:19:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:20:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:21:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:22:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:23:00.000Z",15,10),
                    new EConsoData("2021-10-10T00:24:00.000Z",15,10),

                ];
        }
        return data
    }

    getDateRanges(participantId: number): { participantId: number; from: string; to: string }[] | Promise<{ participantId: number; from: string; to: string }[]> {
        return [
                {from:"2022-05-31T22:00:00.000Z",to:"2022-06-30T21:30:00.000Z",participantId:281},
                {from:"2022-01-31T23:00:00.000Z",to:"2022-02-28T22:30:00.000Z",participantId:281},
                {from:"2021-11-30T23:00:00.000Z",to:"2021-12-31T22:30:00.000Z",participantId:281},
                {from:"2021-10-31T23:00:00.000Z",to:"2021-11-30T22:30:00.000Z",participantId:281},
                {from:"2021-12-31T23:00:00.000Z",to:"2022-01-31T22:30:00.000Z",participantId:281}
            ]
    }

}
