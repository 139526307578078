import {INIT_ERROR, SET_ERROR} from "./errorConstants";

const initialState = {
    status: null,
    message: null,
    variant: null,
};
type TAction = {
     type: any;
     status: number;
     message: string;
     variant: any;
}
function errorReducer(state = initialState, action:TAction ) {
    switch (action.type) {
        case SET_ERROR: {
            return {
                status: action.status,
                message: action.message,
                variant: action.variant,
            };
        }
        case INIT_ERROR: {
            return {
                status: null,
                message: null,
                variant: null,
            };
        }
        default:
            return state;
    }
}

export default errorReducer;