export class Ecoconseil{

    private _type:string;
    private _title:string;
    private _description:string;
    private _hookTxt:string;

    constructor(type:string,title:string, description:string, hookTxt:string ) {
        this._type=type;
        this._description=description;
        this._hookTxt=hookTxt;
        this._title=title;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get hookTxt(): string {
        return this._hookTxt;
    }

    set hookTxt(value: string) {
        this._hookTxt = value;
    }
}