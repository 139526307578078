export class Appmap {
    private readonly _enoconso: string;
    private readonly _enoapp: string;
    private readonly _root: string;

    constructor(enoconso: string, enoapp: string, root: string) {
        this._enoconso = enoconso;
        this._enoapp = enoapp;
        this._root = root;
    }

    get enoconso(): string {
        return this._enoconso;
    }

    get enoapp(): string {
        return this._enoapp;
    }

    get root(): string {
        return this._root;
    }
}