import {contactGatewayInterface} from "../../../corelogic/usecases/contact/contactGatewayInterface";
import client from "../libs/client";
import {Contact} from "../../../corelogic/models/entities/contact/contact";
import {MailSentResponse} from "../../../corelogic/models/entities/contact/mailSentResponse";
import {EOperation} from "../../../corelogic/models/entities/operation/operation";

export default class APIContactGateway implements contactGatewayInterface {
    error: any;
    constructor() {
    };

    setError = (error: boolean) => {
        this.error = error;
    };

    async getContacts(operationId: number): Promise<Contact[]> {
        const {data: contacts} = await client.get(`/communication/recipients`);
        return contacts.map((contact: any) => {
            return new Contact(contact.id, contact['billingDetail.name'], contact['billingDetail.email'])
        })
    }

    async postNewMessage(recipient: string[], message : string): Promise<MailSentResponse> {
        const {data: res} = await client.post(`/communication/send`, {
            recipients: recipient,
            body: message,
        });
        const {accepted, rejected} = res;
        return new MailSentResponse(accepted, rejected)
    }

    async sendMailToQuitOperation(operation : EOperation, participantId : number): Promise<number> {
        const res = await client.delete(`/operations/${operation.id}/participants/${participantId}/remove`, {
            data : {
                participantId : participantId,
                operation : operation
            }
        });
        return Promise.resolve(res.status)
    }

   async getPMO(organizerId: number): Promise<any> {
        const {data: res} = await client.get(`/organizers/${organizerId}`);
        return Promise.resolve(res)
    }


}
