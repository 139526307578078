import {
    GET_COORDINATES,
    GET_ECOCONSEILS,
    GET_PRODUCTION_TYPES,
    GET_WEATHER,
    SET_COORDINATES,
    SET_ECOCONSEILS,
    SET_PRODUCTION_TYPES,
    SET_WEATHER
} from "./consumptionConstants";
import {Ecoconseil} from "../../models/entities/consumption/ecoconseil";
import {Coordinates} from "../../models/entities/consumption/coordinates";
import {DailyWeather} from "../../models/entities/consumption/dailyWeather";

export let ConsumptionActions = {

    getWeather() {
        return {type: GET_WEATHER};
    },
    getEcoConseils() {
        return {type: GET_ECOCONSEILS};
    },
    setWeather( weathers: DailyWeather[] | null) {
        return {type: SET_WEATHER, payload: {weathers}};
    },
    setEcoconseil(ecoconseils: Ecoconseil[] | null) {
        return {type: SET_ECOCONSEILS, payload: {ecoconseils}};
    },
    getProductionTypes() {
        return {type: GET_PRODUCTION_TYPES};
    },
    setProductionTypes(typesOfProduction: string[] | null) {
        return {type: SET_PRODUCTION_TYPES, payload: {typesOfProduction}};
    },
    getCoordinates() {
        return {type : GET_COORDINATES}
    },
    setCoordinates(coordinates : Coordinates){
        return {type : SET_COORDINATES, payload : {coordinates}}
    },
}

