export class EnedisDetail{

    _prm : string;
    _padt : string;
    _city : string;
    _postCode : string;
    _entityType : string | null;
    _address:string;
    _name:string;
    _activationRange : Date[]

    constructor( prm : string, padt : string, city : string, postCode :string,  entityType : string | null, activationRange : Date[], address :string, name : string) {
        this._prm = prm;
        this._padt=padt;
        this._city=city;
        this._postCode = postCode;
        this._entityType=entityType;
        this._activationRange = activationRange
        this._address = address
        this._name = name
    }
    serialize() {
        return {
            prm: this._prm,
            padt:this._padt,
            city:this._city,
            postCode : this._postCode,
            entityType:this._entityType,
            activationRange : this._activationRange,
            address:this._address,
            name:this._name
        }
    }

}
