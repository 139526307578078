import {billingGatewayInterface} from "../../../corelogic/usecases/billing/billingGatewayInterface";
import {Billing} from "../../../corelogic/models/types/Billing";
import {BillHistory} from "../../../corelogic/models/entities/billing/billHistory";

export default class InMemoryBillingGateway implements billingGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };

    getBillingStatus(participantId: number): Billing["billingStatus"] | null {
        switch (participantId) {
            case 1 :
                return {
                    operation: "Malaunay",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "1 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
            case 2 :
                return {
                    operation: "Malaunay",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "2 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
            case 3 :
                return {
                    operation: "Malaunay",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "3 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
            case 4 :
                return {
                    operation: "ACC4",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "4 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
            case 5 :
                return {
                    operation: "ACC4",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "5 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
            default :
                return {
                    operation: "ACC4",
                    nextBillingDate: "2021-12-07T22:00:00.000Z",
                    billingInterval: "6 mois",
                    url: "https://source.unsplash.com/user/erondu/414x896",
                };
        }
    }

    getBillsHistory(participantId: number): BillHistory[] | null {
        return [
            new BillHistory("2020-04-10","2020-05-09", 6.252150347624642, 6 ),
            new BillHistory("2020-05-10","2020-06-09", 7.252150347624642, 7 )
        ]
    }

    downloadBill(participantId: number, billingPeriodEnd: string): Promise<void> {
        return Promise.resolve(undefined);
    }


}