const descendingComparator = (a: { [x: string]: { [x: string]: number; }; }, b: { [x: string]: { [x: string]: number; }; }, orderBy: string | number, type: string | number) => {
    if (b[type][orderBy] < a[type][orderBy]) {
        return -1;
    }
    if (b[type][orderBy] > a[type][orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order: string, orderBy: string | number, type: string | number) => {
    return order === "desc"
        ? (a: { [x: string]: { [x: string]: number; }; }, b: { [x: string]: { [x: string]: number; }; }) => descendingComparator(a, b, orderBy, type)
        : (a: { [x: string]: { [x: string]: number; }; }, b: { [x: string]: { [x: string]: number; }; }) => -descendingComparator(a, b, orderBy, type);
};

const tableSort = (array: any[], comparator: { (a: { [x: string]: { [x: string]: number; }; }, b: { [x: string]: { [x: string]: number; }; }): number; (arg0: any, arg1: any): any; }) => {
    const stabilizedThis = array && array.map((el, index) => [el, index]);
    stabilizedThis && stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis && stabilizedThis.map(el => el[0]);
}

export {tableSort, getComparator};