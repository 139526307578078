import {createSelector} from 'reselect'
import {Authentication} from "../../models/types/Authentication";

const selectAuthentication = (state: { authentication: Authentication; }) => state.authentication;

const selectUser = createSelector(
    selectAuthentication,
    authentication => authentication.user
);

const selectCgu = createSelector(
    selectAuthentication,
    authentication => authentication.cgu
)


const selectIsAuthenticating = createSelector(
    selectAuthentication,
    authentication => authentication.isAuthenticating
)


export {selectUser, selectCgu, selectIsAuthenticating}
