import {informationGatewayInterface} from "../../../corelogic/usecases/information/informationGatewayInterface";
import {TEnoField} from "../../../corelogic/models/entities/information/enoField";
import {BillingDetail} from "../../../corelogic/models/entities/participant/billingDetail";

export default class InMemoryInformationGateway implements informationGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };
    getOperationInformation(operationId : number) : Promise<TEnoField[][]>{
        switch(operationId) {
            case 1 : return Promise.resolve([
                [{_type: "Nom", _value: "DEMO MIXTE",}],
                [{_type: "Operation_ID", _value: operationId.toString(),}],
                [{_type: "Numéro Enedis", _value: "18923828048902",}],
                [{_type: "Statut légal", _value: "-",}],
                [{_type: "Adresse", _value: "19, avenue Champolion",}],
                [{_type: "Ville", _value: "Grenoble",},
                    {_type: "Code Postal", _value: "38000",},],
                [{_type: "Nom du représentant", _value: "DURANT",}],
                [{_type: "Numéro de téléphone", _value: "0638627348",}],
                [{_type: "Email", _value: "TEST@gmail.com",}],
            ]);
            default : return Promise.resolve([
                [{_type: "Nom", _value: "DEFAULT",}],
                [{_type: "Operation_ID", _value: operationId.toString(),}],
                [{_type: "Numéro Enedis", _value: "123456789123456",}],
                [{_type: "Statut légal", _value: "Collectivité territoriale",}],
                [{_type: "Adresse", _value: "7 rue des pommiers",}],
                [{_type: "Ville", _value: "Paris"},
                    {_type: "Code Postal", _value: "75000"},],
                [{_type: "Nom du représentant", _value: "MACRON",}],
                [{_type: "Numéro de téléphone", _value: "0123456789",}],
                [{_type: "Email", _value: "e.macron@gmail.com",}],
            ]);
        }
    }




}
