import {User} from "../../models/entities/authentication/user";
import {
    ACCEPT_CGU,
    CHANGE_PASSWORD,
    GET_USER,
    INIT_USER,
    LOG_IN,
    LOG_OUT,
    SET_CGU,
    SET_INITIALIZED,
    SET_IS_AUTHENTICATING,
    SET_USER
} from "./authenticationConstants";
import {History, LocationState} from 'history';

export let AuthenticationActions = {
    getUser() {
        return {type: GET_USER};
    },
    setUser(user: User) {
        return {type: SET_USER, payload: {user}};
    },
    changePassword ({oldPassword, newPassword} : {oldPassword:string, newPassword:string}) {
        return {type : CHANGE_PASSWORD, payload : {oldPassword, newPassword}}
    },
    setInitialized(initialized: boolean) {
        return {type: SET_INITIALIZED, payload : {initialized}};
    },
    initUser() {
        return {type: INIT_USER};
    },
    logOut(history: History) {
        return {type: LOG_OUT, history};
    },
    logIn({history, email, password}: { history : History<LocationState>, email : string, password : string }) {
        return {type: LOG_IN, history, email, password};
    },
    setCgu(cgu: any) {
        return {type: SET_CGU, payload : {cgu}};
    },
    acceptCgu(cguId: any, history: any) {
        return {type: ACCEPT_CGU, cguId, history};
    },
    setIsAuthenticating(isAuthenticating: boolean) {
        return {type: SET_IS_AUTHENTICATING, payload:{isAuthenticating}};
    },

}

