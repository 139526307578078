import {ActionWithPayload} from "../../redux/createAction";
import {TDatas} from "../../models/types/Datas";
import {
    SET_CONSO_DATE_END,
    SET_CONSO_DATE_START,
    SET_CONSODATA,
    SET_CONSODATA_IS_REAL,
    SET_CURRENTDATE,
    SET_INTERVAL,
    SET_LOCALDATA,
    SET_LOCALDATA_IS_REAL, SET_UPLOADED_DATE_RANGE
} from "./datasConstants";

const initialState = {
    interval: "mois",
    dateFrom :null ,
    dateTo :null ,
    uploadedDateRange:{
        from : null,
        to : null
    },
    currentDate: new Date(Date.now()),
    consoDataIsReal:false,
    consoData: [{}],
    localDataIsReal:false,
    localData: [{}],
};
export default function datasReducer(state = initialState, action: ActionWithPayload<TDatas>) {
    switch (action.type) {
        case SET_INTERVAL: {
            return {...state, interval: action.payload.interval}
        }
        case SET_CONSO_DATE_START: {
            return {
                ...state, dateFrom: action.payload
            };
        }
        case SET_CONSO_DATE_END: {
            return {
                ...state,  dateTo: action.payload
            };
        }
        case SET_CURRENTDATE : {
            return {...state, currentDate: action.payload.currentDate}
        }
        case SET_CONSODATA : {
            return {...state, consoData: action.payload.consoData}
        }
        case SET_LOCALDATA : {
            return {...state, localData: action.payload.localData}
        }
        case SET_CONSODATA_IS_REAL : {
            return {...state, consoDataIsReal: action.payload.consoDataIsReal}
        }
        case SET_LOCALDATA_IS_REAL : {
            return {...state, localDataIsReal: action.payload.localDataIsReal}
        }
        case SET_UPLOADED_DATE_RANGE : {
            return {...state, uploadedDateRange: action.payload}
        }
        default:return state;
    }
}
