import {SET_BILLING_STATUS, SET_BILLS_HISTORY} from './billingConstants';
import {ActionWithPayload} from "../../redux/createAction";
import {Billing} from "../../models/types/Billing";


const initialState = {

    billingStatus: {
        operation: "",
        nextBillingDate: "",
        billingInterval: "",
        url: "",
    },
    billsHistory: [],
};


export default function billingReducer(state = initialState, action: ActionWithPayload<Billing>) {
    switch (action.type) {
        case SET_BILLING_STATUS: {
            return {...state, billingStatus: action.payload.billingStatus};
        }
        case SET_BILLS_HISTORY: {
            return {...state, billsHistory: action.payload.billsHistory};
        }
        default:
            return state;
    }
}

