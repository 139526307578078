export class Weather {
    _type: string;
    _city: string;
    _journee : string;
    _enabled: boolean;

    constructor(type: string, city: string, journee : string, enabled: boolean) {
        this._type = type;
        this._city = city;
        this._enabled = enabled;
        this._journee = journee;
    }
}