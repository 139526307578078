import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {DOWNLOAD_BILL, GET_BILLING_STATUS, GET_BILLS_HISTORY} from "./billingConstants";
import {BillingActions} from "./billingActions";
import {billingGatewayInterface} from "./billingGatewayInterface";
import {Billing} from "../../models/types/Billing";
import {BillHistory} from "../../models/entities/billing/billHistory";
import {selectCurrentParticipantId} from "../participants/participantsSelector";
import {handleErrorMessage} from "../../../adapters/primary/libs/handleErrorMessage";

function* getBillingStatus() {
    try {
        const participantId:number = yield select(selectCurrentParticipantId)
        const billingGatewayInterface: billingGatewayInterface = yield getContext('billingGatewayContext');
        const billingStatus: Billing["billingStatus"] = yield call(billingGatewayInterface.getBillingStatus, participantId);
        yield put(
            BillingActions.setBillingStatus(billingStatus),
        );
    } catch (error:any) {
        yield put(
            setError({status: error?.response && error?.response?.status}),
        );
        console.error(error);
    }
}

function* getBillsHistory() {
    try {
        const participantId:number = yield select(selectCurrentParticipantId)
        const billingGatewayInterface: billingGatewayInterface = yield getContext('billingGatewayContext');
        const billsHistory: BillHistory[] = yield call(billingGatewayInterface.getBillsHistory, participantId);
        yield put(
            BillingActions.setBillsHistory(billsHistory),
        );
    } catch (error:any) {

        yield put(
            setError({status:error?.response?.status, message : "[GET BillsHistory] : "+handleErrorMessage(error?.response?.status)})
        );
        console.error("Fetch Bills History ...",error);
    }
}
function* downloadBill(action : any) {
    try {

        const participantId:number = yield select(selectCurrentParticipantId)
        const {billingPeriodEnd}  = action.payload
        const billingGatewayInterface: billingGatewayInterface = yield getContext('billingGatewayContext');
        yield call(billingGatewayInterface.downloadBill, participantId, billingPeriodEnd);

    } catch (error:any) {
        yield put(
            setError({status:error.response.status, message : "[GET DownloadBill] : "+handleErrorMessage(error.response.status)})
        );
        console.error(error);
    }
}

export function* downloadBillSaga() {
    yield takeLatest(DOWNLOAD_BILL, downloadBill)
}
export function* getBillsHistorySaga() {
    yield takeLatest(GET_BILLS_HISTORY, getBillsHistory);
}

export function* getBillingStatusSaga() {
    yield takeLatest(GET_BILLING_STATUS, getBillingStatus);
}

export default [getBillingStatusSaga, getBillsHistorySaga, downloadBillSaga];
