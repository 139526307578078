import {documentGatewayInterface} from "../../../corelogic/usecases/document/documentGatewayInterface";
import {MyDocument} from "../../../corelogic/models/types/Document";
import {DocumentFolder} from "../../../corelogic/models/entities/document/documentFolder";
import {DocumentFile} from "../../../corelogic/models/entities/document/documentFile";

export default class InMemoryDocumentGateway implements documentGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };

    async getDocument(participantId: number, operationId: number): Promise<MyDocument | null> {
        const folderChilds = [
            new DocumentFolder(
                "Opération ACC",
                [
                    new DocumentFile("mon opération à Malaunay.pdf", "https://children.pdf", 1),
                    new DocumentFile("une opé ça marche comment ?.pdf", "https://children2.pdf", 2),
                ],

                null,
            )
        ]


        const folders = [
            new DocumentFolder(
                "Mes factures ",
                [new DocumentFile("facture 17-10-21.pdf", "https://children.pdf", 5), new DocumentFile("facture 17-09-21.pdf", "https://children2.pdf", 6)],
                null,
            ),
            new DocumentFolder(
                "Mes documents partagés",
                [new DocumentFile("Gérer sa conso.pdf", "https://children.pdf", 7),],
                folderChilds,
            ),
        ]

        return Promise.resolve({
            folders: folders,
            files: null,
        });
    }

    downloadFile(fileId: number, operationId: number): Promise<void> {
        window.location.href = "https://source.unsplash.com/random";
        return Promise.resolve();
    }
}
