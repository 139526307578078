import {participantsGatewayInterface} from "../../../corelogic/usecases/participants/participantsGatewayInterface";
import {EParticipant} from "../../../corelogic/models/entities/participant/participant";
import {EnedisDetail} from "../../../corelogic/models/entities/participant/enedisDetail";
import {BillingDetail} from "../../../corelogic/models/entities/participant/billingDetail";

export default class InMemoryParticipantsGateway implements participantsGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };

    getParticipantsByJwt(): EParticipant[] | null {
        const enedisDetail1 = new EnedisDetail("129381203891290", "293871983717", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")
        const enedisDetail2 = new EnedisDetail("129381203891291", "293871983718", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")
        const enedisDetail3 = new EnedisDetail("129381203891292", "293871983710", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")
        const enedisDetail4 = new EnedisDetail("129381203891293", "293871983712", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")
        const enedisDetail5 = new EnedisDetail("129381203891294", "293871983713", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")
        const enedisDetail6 = new EnedisDetail("129381203891295", "293871983716", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", "")

        const billingDetail = new BillingDetail(1, "toto", "1 court Berriat", "83560", "Valensole", "toto@email.com", "07 77 86 57 12", "1 billion euros")
        return [
            {
                _id: 1,
                _name: 'Denis Brogniart',
                _userId: 1,
                _operationId: 1,
                _enedisDetail: enedisDetail1,
                _billingDetail: billingDetail,
            },
            {
                _id: 2,
                _name: 'participant2',
                _userId: 1,
                _operationId: 1,
                _enedisDetail: enedisDetail2,
                _billingDetail: billingDetail,

            },
            {
                _id: 3,
                _name: 'participant3',
                _userId: 1,
                _operationId: 1,
                _enedisDetail: enedisDetail3,
                _billingDetail: billingDetail,
            },
            {
                _id: 4,
                _name: 'participant4',
                _userId: 1,
                _operationId: 2,
                _enedisDetail: enedisDetail4,
                _billingDetail: billingDetail,
            },
            {
                _id: 5,
                _name: 'participant5',
                _userId: 1,
                _operationId: 2,
                _enedisDetail: enedisDetail5,
                _billingDetail: billingDetail,
            },
            {
                _id: 6,
                _name: 'participant6',
                _userId: 1,
                _operationId: 2,
                _enedisDetail: enedisDetail6,
                _billingDetail: billingDetail,
            },
        ];
    }

    updateEnedisDetail({
                           enedisDetail,
                           participantId
                       }: { enedisDetail: EnedisDetail, participantId: number }): Promise<EnedisDetail> {
        return Promise.resolve(new EnedisDetail("129381203891290", "293871983717", "Grenoble", "38000", null, [new Date(-8640000000000000), new Date(8640000000000000)], "", ""))
    }

    updateBillingDetail({
                            billingDetail,
                            participantId
                        }: { billingDetail: BillingDetail; participantId: number }): Promise<BillingDetail> {
        return Promise.resolve(new BillingDetail(1, "toto", "1 court Berriat", "83560", "Valensole", "toto@email.com", "07 77 86 57 12", "1 billion euros"));
    }
}
