import {
    GET_APPMAP, SET_APPMAP
} from "./navigationConstants";
import {Appmap} from "../../models/entities/navigation/Appmap";

export let NavigationActions = {
    getAppmap() {
        return {type: GET_APPMAP};
    },
    setAppmap(appmap: Appmap) {
        return {type: SET_APPMAP, payload: {appmap}};
    },

}

