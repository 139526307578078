import {contactGatewayInterface} from "../../../corelogic/usecases/contact/contactGatewayInterface";
import {Contact} from "../../../corelogic/models/entities/contact/contact";
import {MailSentResponse} from "../../../corelogic/models/entities/contact/mailSentResponse";
import {EOperation} from "../../../corelogic/models/entities/operation/operation";

export default class InMemoryContactGateway implements contactGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };

    getContacts(operationId: number): Promise<Contact[]> {
        switch(operationId) {
            case 1 :return Promise.resolve([
                {
                    participantId: 0,
                    name: "GAILLOT",
                    mail: "thomas.gaillot@grenoble-inp.org",
                },
                {
                    participantId: 1,
                    name: "Durant",
                    mail: "GDurant@enogrid.com",
                },
                {
                    participantId: 2,
                    name: "Ben",
                    mail: "Ben@enogrid.com",
                },
            ]);
            default :return  Promise.resolve([
                {
                    participantId: 3,
                    name: "test",
                    mail: "thomas.gaillot@grenoble-inp.org",
                },
                {
                    participantId: 4,
                    name: "Bertrand",
                    mail: "BJeuno@enogrid.com",
                },
                {
                    participantId: 5,
                    name: "Thibault",
                    mail: "enothibault@enogrid.com",
                },
            ]);
        }


    }

    async postNewMessage(recipient:string[], message : string): Promise<MailSentResponse> {
        return Promise.resolve(new MailSentResponse(["contact@selectio.nné"],[]));
    }

     sendMailToQuitOperation(operation : EOperation, participantId : number): Promise<number> {
        return Promise.resolve(200);
    }

    getPMO(organizerId: number): Promise<any> {
        return Promise.resolve({username : "testy", email :"test@y.com"});
    }
}




