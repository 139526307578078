import {billingGatewayInterface} from "../../../corelogic/usecases/billing/billingGatewayInterface";
import {Billing} from "../../../corelogic/models/types/Billing";
import {BillHistory} from "../../../corelogic/models/entities/billing/billHistory";
import client from "../libs/client";

export default class APIBillingGateway implements billingGatewayInterface {
    error: any;

    setError = (error: boolean) => {
        this.error = error;
    };


    async getBillsHistory(participantId : number): Promise<BillHistory[] | null> {
        const  {data : bills} = await client.get(`/participants/${participantId}/bills`);
        return bills.map((bill:any)=> {
             return new BillHistory(bill.billingPeriodStart, bill.billingPeriodEnd, bill.total, bill.synthesisDocumentId)
        })
    }
    async downloadBill(participantId : number, billingPeriodEnd : string) : Promise<void> {
        const url = `${process.env.REACT_APP_API_URL}/participants/${participantId}/bills/synthesis-document?billingPeriodEnd=${billingPeriodEnd}`
        window.location.href = url
    }

    getBillingStatus(participantId: number): Billing["billingStatus"] | null {
        return null;
    }


}