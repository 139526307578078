import {informationGatewayInterface} from "../../../corelogic/usecases/information/informationGatewayInterface";
import {TEnoField} from "../../../corelogic/models/entities/information/enoField";
import client from "../libs/client";

export default class APIInformationGateway implements informationGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };




    async getOperationInformation(operationId: number): Promise<TEnoField[][]> {
        let operationInformations: TEnoField[][] = []
        const {data: operation} = await client.get(`/operations/${operationId}`)
        operationInformations.push([{_type: "Nom", _value: operation.name || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Operation_ID", _value: operation.id || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Numéro Enedis", _value: operation.enedisId || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Statut légal", _value: operation.pmoLegalStatusId || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Adresse", _value: operation.pmoAddress || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Ville", _value: operation.pmoCity || "non renseigné", _editable: false}, {_type: "Code postal", _value: operation.pmoPostCode || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Nom du représentant", _value: operation.representativeName || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Téléphone représentant", _value: operation.representativePhoneNumber || "non renseigné", _editable: false}])
        operationInformations.push([{_type: "Email représentant", _value: operation.representativeEmail || "non renseigné", _editable: false}])
        return operationInformations
    }




}
