import {documentGatewayInterface} from "../../../corelogic/usecases/document/documentGatewayInterface";
import {MyDocument} from "../../../corelogic/models/types/Document";
import client from "../libs/client";
import {DocumentFolder} from "../../../corelogic/models/entities/document/documentFolder";
import {DocumentFile} from "../../../corelogic/models/entities/document/documentFile";

export default class APIDocumentGateway implements documentGatewayInterface {
    error: any;

    constructor() {
    };

    setError = (error: boolean) => {
        this.error = error;
    };

    async getDocument(participantId: number, operationId: number): Promise<MyDocument | null> {

        const {data} = await client.get(`/participants/${participantId}/documents/list-files?operationId=${operationId}`)

        const recursiveCall = (folder: any, name: string): DocumentFolder => {
            return folder.reduce((acc: DocumentFolder, curr: any) => {
                if(curr.key){
                    const documentFile: DocumentFile = new DocumentFile(curr.name, "", curr.id)
                    acc.addFilesChild(documentFile)
                } else {
                    acc.addFoldersChild(recursiveCall(curr.childs, curr.name))
                }

                return acc;

            }, new DocumentFolder(
                name,
                [],
                [],
            ))
        }

        return data.reduce((acc: any, curr: any) => {
            if(curr.key){
                const documentFile: DocumentFile =  new DocumentFile(curr.name, "", curr.id)
                acc.files.push(documentFile)
            } else {
                acc.folders.push(recursiveCall(curr.childs, curr.name))
            }

            return acc;

        }, {
            folders: [],
            files: [],
        })
    }

   async downloadFile (fileId: number, operationId: number): Promise<void> {
            const { data } = await client.get(`/documents/${fileId}/presigned-url/?operationId=${operationId}`)
            const { url } = data

            window.location.href = url;
    }



}
