import client from "../libs/client";
import {operationGatewayInterface} from "../../../corelogic/usecases/operation/operationGatewayInterface";
import {EOperationNumbers} from "../../../corelogic/models/entities/operation/operationNumbers";

export default class APIOperationGateway implements operationGatewayInterface {
    error:any;

    constructor() {
    };

    setError = (error:boolean) => {
        this.error = error;
    };


    getOrganizerByJwt = () => {
        return {
            id: 1,
            name: 'test',
            userId: 1,
        };
    };

    getOperations = (ids: any) => {
        return [
            {
                id: ids,
            }
        ]
    };

    getObserverOperations = () => {
        return [
            {
                id: 1,
            }
        ]
    };

    getOrganizerOperations = (organizerId: any) => {
        return [
            {
                id: 1,
            }
        ]
    };
    getParticipantsByJwt(): any[] {
        return [{
            id: 1,
            name: 'test',
            userId: 1,
            operationId: 1
        }];
    }

    getOperationParticipants = (operationId: number) => {
        return [
            {
                _id: 1,
                _name: 'participant1',
                _userId: 1,
                _operationId: operationId
            },
            {
                _id: 2,
                _name: 'participant2',
                _userId: 1,
                _operationId: operationId
            },
            {
                _id: 3,
                _name: 'participant3',
                _userId: 1,
                _operationId: operationId
            }];
    };

    getOperationObservers = (operationId: number) => {
        return [{
            id: 1,
            name: 'observer name',
            userId: 1,
            operationId: operationId
        }];
    };

    getPresignedLogo(operation: any): any {
    }

    updateOperation(operation: any): any {
    }

    uploadLogo(operation: any, formData: any): any {
    }

    async getParticipantOperation(operationId : number):Promise<any> {
        const {data : operation} = await client.get(`/operations/${operationId}`);
        return operation
    }
    async getOperationInfos(operationId: number, latestDayWithDataAvailable: string):Promise<EOperationNumbers> {
        const {data: numbersAboutOperation} = await client.get(`/operations/${operationId}/numbers`, {
            params : {
                latestDayWithDataAvailable : latestDayWithDataAvailable
            }
        })
        return new EOperationNumbers(numbersAboutOperation.consumers, numbersAboutOperation.producers, numbersAboutOperation.powerExchanged)
    }

    async getLatestDateWithData(operationId: number): Promise<any> {
        const {data: latestDay} = await client.get(`/operations/${operationId}/measurements/latest-day-with-nova-data`)
        return Promise.resolve(latestDay)
    }


}
