import {billCheckGatewayInterface} from "../../../corelogic/usecases/bill-check/billCheckGatewayInterface";
import client from "../libs/client";

export default class APIBillCheckGateway implements billCheckGatewayInterface {
    async getResults(
        participantId: string,
        startDate: string,
        endDate: string,
        consumerType: string,
        contractType: string,
        basekWhprice: string,
        peakkWhPrice: string,
        offPeakkWhPrice: string,
        summerOffPeakkWhPrice: string,
        summerPeakkWhPrice: string,
        winterOffPeakkWhPrice: string,
        winterPeakkWhPrice: string,
        offPeakStart: string,
        peakStart: string,
        TRV: boolean
    ): Promise<string> {
        const {data} = await client.get(`/participants/${participantId}/economy/consumer`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                contractType: contractType,
                consumerType: consumerType,
                basekWhPrice: basekWhprice,
                peakkWhPrice: peakkWhPrice,
                offPeakkWhPrice: offPeakkWhPrice,
                summerOffPeakkWhPrice: summerOffPeakkWhPrice,
                summerPeakkWhPrice: summerPeakkWhPrice,
                winterOffPeakkWhPrice: winterOffPeakkWhPrice,
                winterPeakkWhPrice: winterPeakkWhPrice,
                offPeakStart: offPeakStart,
                peakStart: peakStart,
                TRV: TRV
            }
        })

        return Promise.resolve(data.economy.toFixed(2))
    }

    async getSelfConsumedMetrics(participantId: string, dateFrom: Date, dateTo: Date): Promise<any> {
        const {data} = await client.get(`/participants/${participantId}/measurements/metrics`, {
            params: {
                dateFrom: dateFrom,
                dateTo: dateTo,
                scope: "Consommateur",
            }
        })

        return Promise.resolve({selfConsumed: data.selfConsumed, globalConsumption: data.globalConsumption})
    }

    async getTRVs():Promise<any> {
        const {data} = await client.get(`/trv-prices/last`)
        return Promise.resolve({id : data.id, basekWhPrice : data.basekWhPrice,peakkWhPrice : data.peakkWhPrice, offPeakkWhPrice : data.offPeakkWhPrice, periodStart : data.periodStart})
    }

    async getPriceConfig(participantId: string): Promise<any> {
        const {data} = await client.get(`/participants/${participantId}/economy/consumer-price-config`);
        return data;

    }


}
