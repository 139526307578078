import {TParticipants} from "../../models/types/Participants";
import {createSelector} from "reselect";
import {TEnoField} from "../../models/entities/information/enoField";
import {ADDRESS, CITY, EMAIL, NAME, PHONENUMBER, POSTCODE, PRM} from "../../../config/app-config";
import {EParticipant} from "../../models/entities/participant/participant";
import moment from "moment";


const participants = (state: { participants: TParticipants }) => state.participants;

const selectUserParticipants = createSelector(
    participants,
    participants => participants && participants.participants
)
const selectCurrentParticipant = createSelector(
    participants,
    participants => participants && participants.currentParticipant
)

const selectCurrentParticipantId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._id
)
const selectCurrentParticipantOperationId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._operationId
)
const selectCurrentParticipantUserId = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._userId
)

// My-Account
const  selectCurrentParticipantEnedisDetail = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._enedisDetail
)
const selectCurrentParticipantBillingDetail = createSelector(
    selectCurrentParticipant,
    currentParticipant => currentParticipant._billingDetail
)
const selectParticipantInfos = createSelector(
    selectCurrentParticipant,
    currentParticipant => {
        const participantInfos: TEnoField[][] = []
        participantInfos.push([{_type: PRM, _value: currentParticipant._enedisDetail._prm || "", _editable: false}])
        participantInfos.push([{_type: ADDRESS, _value: currentParticipant._enedisDetail._address|| "", _editable: true}])
        participantInfos.push([{_type: CITY, _value: currentParticipant._enedisDetail._city|| "", _editable: true}, {_type: POSTCODE, _value: currentParticipant._enedisDetail._postCode|| "", _editable: true}])
        participantInfos.push([{_type: NAME, _value: currentParticipant._enedisDetail._name|| "", _editable: true}])
        return participantInfos || []
    }
)
const selectBillingInfos = createSelector(
    selectCurrentParticipant,
    currentParticipant => {
        const participantInfos: TEnoField[][] = []
        participantInfos.push([{_type: NAME, _value: currentParticipant._billingDetail._name || "", _editable: true}])
        participantInfos.push([{_type: ADDRESS, _value: currentParticipant._billingDetail._address|| "", _editable: true}])
        participantInfos.push([{_type: CITY, _value: currentParticipant._billingDetail._city|| "", _editable: true}, {_type: POSTCODE, _value: currentParticipant._billingDetail._postCode|| "", _editable: true}])
        participantInfos.push([{_type: PHONENUMBER, _value: currentParticipant._billingDetail._phoneNumber|| "", _editable: true}])
        participantInfos.push([{_type: EMAIL, _value: currentParticipant._billingDetail._email|| "", _editable: true}])
        return participantInfos || []
    }
)
const selectParticipantsEnedisDetails = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._enedisDetail)
)
const selectParticipantPrm = createSelector(
    selectUserParticipants,
    participants => participants.map((participant:EParticipant) => participant._enedisDetail._prm)
)


export {
    selectBillingInfos,
    selectCurrentParticipantBillingDetail,
    selectParticipantInfos,
    selectUserParticipants,
    selectCurrentParticipant,
    selectCurrentParticipantId,
    selectCurrentParticipantEnedisDetail,
    selectCurrentParticipantOperationId,
    selectCurrentParticipantUserId,
    selectParticipantPrm,
    selectParticipantsEnedisDetails
}
