import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";

const checkBrowser = () => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return 'Safari';
    else if (navigator.userAgent.indexOf("MSIE") !== -1 || !!document.DOCUMENT_NODE) return 'IE'
    else return 'Other'
}

const safariWarning = (
    <Fragment>
        <Typography variant="caption" display="block">
            Veuillez autoriser les cookies tiers ou utiliser un autre navigateur que Safari pour pouvoir vous connecter.
        </Typography>
        <Typography variant="caption" display="block">
            Safari {">"} Préférences {">"} Confidentialité {">"} Décocher "Empêcher le suivi sur plusieurs domaines"
        </Typography>
    </Fragment>
);

const ieWarning = (
    <Fragment>
        <Typography variant="caption" display="block">
            Pour une expérience optimale, vous devriez utiliser un navigateur autre que Internet Explorer
        </Typography>
    </Fragment>
)

const makeWarning = () => {
    switch(checkBrowser()) {
        case 'Safari':
            return safariWarning;
        case 'IE':
            return ieWarning;
        default:
            return null;
    }
}

export default () => {
    return makeWarning();
}