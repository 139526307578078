export class DocumentFile{

    _name:string;
    _url : string;
    id: number;

    constructor(name:string, url : string, id: number) {
        this._name=name
        this._url = url;
        this.id = id;
    }
}