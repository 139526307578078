import {ActionWithPayload} from "../../redux/createAction";
import {SET_OPERATION_LEFT_STATUS, SET_CONTACTS, SET_NEW_MESSAGE} from "./contactConstants";


const initialState = {
    contacts: [],
    newMessageState : null,
    operationLeftStatus : {}
};


export default function documentReducer(state = initialState, action: ActionWithPayload<any>) {
    switch (action.type) {
        case SET_CONTACTS: {
            return {
                ...state,
                contacts: action.payload.contacts
            };
        }
        case SET_NEW_MESSAGE: {
            return {
                ...state,
                newMessageState: action.payload.newMessage
            };
        }
        case SET_OPERATION_LEFT_STATUS : {
            return {
                ...state,
                operationLeftStatus: {message : action.payload.message, variant : action.payload.variant}
            };
        }
        default:
            return state;
    }
}

