// error actions

import {INIT_ERROR, SET_ERROR} from "./errorConstants";

export function setError({status, message, variant}: any) {
    return {type: SET_ERROR, status, message, variant};
}

export function initError() {
    return {type: INIT_ERROR};
}