import {createSelector} from 'reselect'

const selectMeasurement = (state: { measurement: any; }) => state.measurement;

//Measurements

const selectMetrics = createSelector(
    selectMeasurement,
    measurement => measurement.metrics
);

const selectCDC = createSelector(
    selectMeasurement,
    measurement => measurement.cdc
);

const selectMetricsData = createSelector(
    selectMetrics,
    metrics => metrics.data
);

const selectMetricsLoading = createSelector(
    selectMetrics,
    metrics => metrics.loading
);


const selectCDCData = createSelector(
    selectCDC,
    cdc => cdc.data
);


const selectCDCLoading = createSelector(
    selectCDC,
    cdc => cdc.loading
);


const selectConsumptionData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.consumption
);

const selectProductionData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.production
);

const selectAutoConsumptionData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.autoConsumption
);

const selectSurplusData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.surplus
);

const selectExtProductionData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.extProduction
);

const selectExtConsumptionData = createSelector(
    selectCDCData,
    cdc => cdc && cdc.extConsumption
);


const handleMetric = (metric: { prm: any; padt: any; name: any; power: any; participantId: any; }[]) => {
    return metric.map(({ prm, padt, name, power, participantId }) => {
        return {
            prm,
            padt,
            id: participantId,
            name,
            value: power || 0,
        };
    })
};

//filter
const selectFilter = createSelector(
    selectMeasurement,
    measurement => measurement.filter
);

const selectSelectedDates = createSelector(
    selectFilter,
    periodFilter => periodFilter.selectedDates
);

export {
    selectMeasurement,
    selectMetricsData,
    selectMetricsLoading,
    selectCDCLoading,
    selectConsumptionData,
    selectProductionData,
    selectAutoConsumptionData,
    selectSurplusData,
    selectExtProductionData,
    selectExtConsumptionData,
    handleMetric,
    selectFilter,
    selectSelectedDates,
}