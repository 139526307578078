import {createSelector} from 'reselect'
import {defaultTheme, operationFunctionalities} from "../../../config/app-config";
import {getComparator, tableSort} from "../../../adapters/primary/libs/sortTable";
import {selectSelectedDates} from "../measurement/sharedSelectors";
import {dateRangeOverlaps} from "../../../adapters/primary/libs/datesLib";
import {Operation} from "../../models/types/Operation";

const operation = (state: { operation: Operation; }) => state.operation;

// My Operation
const selectOperationInfos = createSelector(
    operation,
    operation => operation && operation.operationInfos
)


// Other


const selectSelectedOperation = createSelector(
    operation,
    operation => operation.selectedOperation
);
const selectSelectedOperationName  = createSelector(
    selectSelectedOperation,
    operation => operation && operation.name
);
const selectSelectedOperationId = createSelector(
    selectSelectedOperation,
    operation => operation && operation.id
);
const selectOperationOrganizerId = createSelector(
    selectSelectedOperation,
    selectedOperation => selectedOperation && selectedOperation.organizerId
);
const selectOperations = createSelector(
    operation,
    operation => operation.operations
);


const selectFilter = createSelector(
    operation,
    operation => operation.filter
);

const selectSorting = createSelector(
    selectFilter,
    filter => filter.sorting,
);

const selectSearch = createSelector(
    selectFilter,
    filter => filter.search,
);

const selectLatestDateWithData = createSelector(
    operation,
    operation => operation && operation.latestDateWithData
)


const selectParticipants = createSelector(
    selectSelectedOperation,
    selectSorting,
    selectSearch,
    (selectedOperation, { order, orderBy, type }, search) => {
        let participants = selectedOperation ? selectedOperation.consumers && selectedOperation.consumers.concat(selectedOperation.producers) : [];
        participants = participants && participants.filter((participant: { enedisDetail: { padt: any; prm: any; }; billingDetail: { name: string; }; }) => {
            const enedisId = participant && participant.enedisDetail && (participant.enedisDetail.padt || participant.enedisDetail.prm);
            return search === '' || participant.billingDetail && participant.billingDetail.name && participant.billingDetail.name.toLowerCase().includes(search.toLowerCase()) ||
                enedisId && enedisId.toLowerCase().includes(search.toLowerCase())
        });
        if (order !== '' && orderBy !== '') {
            participants = tableSort(participants, getComparator(order, orderBy, type));
        }
        return participants;
    },
);


const selectType = createSelector(
    selectFilter,
    filter => filter.participantType,
);

// TODO handle error when we will use this function
/*const selectParticipantsAndObservers = createSelector(
    selectSelectedOperation,
    selectType,
    (operation, type) => {
        let participants = [];
        if (operation) {
            const data = {
                consumers: operation[participantsFilterTypes.CONSUMERS].map((consumer: { name: any; billingDetail: { name: any; }; enedisDetail: { prm: any; }; }) => {
                    const name = consumer.name ? consumer.name : consumer.billingDetail ? consumer.billingDetail.name : consumer.enedisDetail ? consumer.enedisDetail.prm : '';
                    return { ...consumer, name, isConsumer: true };
                }),
                producers: operation[participantsFilterTypes.PRODUCERS].map((producer: { name: any; billingDetail: { name: any; }; enedisDetail: { padt: any; }; }) => {
                    const name = producer.name ? producer.name : producer.billingDetail ? producer.billingDetail.name : producer.enedisDetail ? producer.enedisDetail.padt : '';
                    return { ...producer, name, isProducer: true };
                }),
                observers: operation[participantsFilterTypes.OBSERVERS].map((observer: { name: any; }) => {
                    const name = observer.name ? observer.name : '';
                    return { ...observer, name, isObserver: true };
                }),
            };
            participants = operation ? type !== 'all' ?  data[type] :
                [...data.consumers, ...data.producers, ...data.observers] :
                [];
            participants.sort((a: { name: string; }, b: { name: any; }) => {
                return a.name.localeCompare(b.name) >= 0 ? 1 : -1;
            });
        }
        return participants;

    },
);*/

const selectFunctionalities = createSelector(
    selectSelectedOperation,
    operation => operation && operation.functionalities
);

const selectIsOperationACI = createSelector(
    selectFunctionalities,
    functionalities => functionalities && functionalities.indexOf(operationFunctionalities.ACI) > -1
);

const selectShowGesKpi = createSelector(
    selectFunctionalities,
    functionalities => functionalities && functionalities.indexOf(operationFunctionalities.GES_KPI) > -1
);

const selectTheme = createSelector(
    selectSelectedOperation,
    operation => operation && operation.theme,
)

const selectPrimaryColor = createSelector(
    selectTheme,
    themeFromPMO => themeFromPMO?._primary || defaultTheme._primary
)
const selectSecondaryColor = createSelector(
    selectTheme,
    themeFromPMO => themeFromPMO?._secondary || defaultTheme._secondary
)
const selectDoesOperationUseEnphaseData = createSelector(
    selectSelectedOperation,
    operation => operation && operation.useEnphaseData,

)

const selectActiveParticipants = createSelector(
    selectSelectedOperation,
    selectSelectedDates,
    (operation, selectedDates) => {
        return operation && {consumers: operation.consumers.filter((consumer: { enedisDetail: { activationRange: any; }; }) => dateRangeOverlaps(selectedDates, consumer.enedisDetail && consumer.enedisDetail.activationRange)),
            producers: operation.producers.filter((producer: { enedisDetail: { activationRange: any; }; }) => dateRangeOverlaps(selectedDates, producer.enedisDetail && producer.enedisDetail.activationRange))}
    },

)

export {
    selectPrimaryColor,
    selectSecondaryColor,
    selectOperationOrganizerId,
    selectSelectedOperation,
    selectSelectedOperationId,
    selectOperations,
    selectParticipants,
    selectSorting,
    // selectParticipantsAndObservers,
    selectSelectedOperationName,
    selectType,
    selectIsOperationACI,
    selectShowGesKpi,
    selectTheme,
    selectDoesOperationUseEnphaseData,
    selectActiveParticipants,
    selectOperationInfos,
    selectLatestDateWithData
}
