import {GET_QUESTIONS, SET_QUESTIONS} from "./helpConstants";
import {Question} from "../../models/entities/help/question";


export let HelpActions = {
    getQuestions(operationId: number) {
        return {type: GET_QUESTIONS, operationId};
    },
    setQuestions(recurrentQuestions: {category : string, questions : Question[]}[]){
        return {type: SET_QUESTIONS, payload: {recurrentQuestions}};
    },
}

