export const SET_PRM = 'SET_PRM'
export const SET_DATE_START = 'SET_DATE_START'
export const SET_DATE_END = 'SET_DATE_END'
export const SET_CONSUMER_TYPE = 'SET_CONSUMER_TYPE'
export const SET_CONTRACT_TYPE = 'SET_CONTRACT_TYPE'
export const SET_BASE_KWH_PRICE = 'SET_BASE_KWH_PRICE'
export const SET_PRICE_CONFIG = 'SET_PRICE_CONFIG'

export const SET_PEAK_KWH_PRICE = 'SET_PEAK_KWH_PRICE'
export const SET_OFF_PEAK_KWH_PRICE = 'SET_OFF_PEAK_KWH_PRICE'

export const SET_PEAK_START = 'SET_PEAK_START'
export const SET_OFF_PEAK_START= 'SET_OFF_PEAK_START'

export const GET_RESULTS = 'GET_RESULTS'
export const SET_RESULTS_ECONOMY = 'SET_RESULTS_ECONOMY'
export const GET_PRICE_CONFIG = 'GET_PRICE_CONFIG'

export const SET_WINTER_PEAK_KWH_PRICE = 'SET_WINTER_PEAK_KWH_PRICE'
export const SET_WINTER_OFF_PEAK_KWH_PRICE = 'SET_WINTER_OFF_PEAK_KWH_PRICE'
export const SET_SUMMER_PEAK_KWH_PRICE = 'SET_SUMMER_PEAK_KWH_PRICE'
export const SET_SUMMER_OFF_PEAK_KWH_PRICE = 'SET_SUMMER_OFF_PEAK_KWH_PRICE'

export const GET_RESULTS_SELF_CONSUMED = 'GET_RESULTS_SELF_CONSUMED'
export const SET_RESULTS_SELF_CONSUMED = 'SET_RESULTS_SELF_CONSUMED'
export const SET_TRV = 'SET_TRV'
export const SET_GLOBAL_CONSUMPTION_METRIC = 'SET_GLOBAL_CONSUMPTION_METRIC'
export const GET_TRV = 'GET_TRV'
export const SET_HAS_TRV = 'SET_HAS_TRV'

export let BillCheckActions = {
    setPRM(prm: string) {
        return {type: SET_PRM, payload: prm};
    },
    setDateStart(date: Date) {
        return {type: SET_DATE_START, payload: date};
    },
    setDateEnd(date: Date) {
        return {type: SET_DATE_END, payload: date};
    },
    setConsumerType(type: string | null) {
        return {type: SET_CONSUMER_TYPE, payload: type};
    },
    setContractType(contractType: string) {
        return {type: SET_CONTRACT_TYPE, payload: contractType};
    },
    setBasekWhPrice(basekWhPrice: string) {
        return {type: SET_BASE_KWH_PRICE, payload: basekWhPrice};
    },
    setPeakPrice(peakkWhPrice: string) {
        return {type: SET_PEAK_KWH_PRICE, payload: peakkWhPrice};
    },
    setOffPeakPrice(offPeakkWhPrice: string) {
        return {type: SET_OFF_PEAK_KWH_PRICE, payload: offPeakkWhPrice};
    },

    setPeakWinterPrice(peakkWhWinterPrice: string) {
        return {type: SET_WINTER_PEAK_KWH_PRICE, payload: peakkWhWinterPrice};
    },
    setOffPeakWinterPrice(offPeakkWhWinterPrice: string) {
        return {type: SET_WINTER_OFF_PEAK_KWH_PRICE, payload: offPeakkWhWinterPrice};
    },
    setPeakSummerPrice(peakkWhSummerPrice: string) {
        return {type: SET_SUMMER_PEAK_KWH_PRICE, payload: peakkWhSummerPrice};
    },
    setOffPeakSummerPrice(offPeakkWhSummerPrice: string) {
        return {type: SET_SUMMER_OFF_PEAK_KWH_PRICE, payload: offPeakkWhSummerPrice};
    },

    getResults() {
        return { type: GET_RESULTS };
    },
    getSelfConsumedMetrics() {
        return {type: GET_RESULTS_SELF_CONSUMED}
    },
    setSelfConsumedMetrics(selfConsumedMetric: string) {
        return {type: SET_RESULTS_SELF_CONSUMED, payload: selfConsumedMetric}
    },
    setGlobalConsumptionMetric (globalConsumptionMetric: string) {
        return {type: SET_GLOBAL_CONSUMPTION_METRIC, payload: globalConsumptionMetric}
    },
    setResults(result: any) {
        return {type: SET_RESULTS_ECONOMY, payload: result};
    },
    setHasTRV(hasTRV :boolean){
        return {type: SET_HAS_TRV, payload: hasTRV};
    },
    getTRV(){
        return {type : GET_TRV}
    },
    setTRV(TRVs : any){
        return {type : SET_TRV, payload : TRVs}
    },
    setPeakStart(peakStart :string){
        return {type: SET_PEAK_START, payload: peakStart};
    },
    setOffPeakStart(offPeakStart :string){
        return {type: SET_OFF_PEAK_START, payload: offPeakStart};
    },
    getPriceConfig(){
        return {type: GET_PRICE_CONFIG};
    }

}

