import React, {ComponentType} from 'react';
import Slide from "@material-ui/core/Slide";
import {TransitionProps} from "@material-ui/core/transitions";

const PopupTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default PopupTransition;
