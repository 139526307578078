import { SET_FOLDERS,SET_FILES } from './documentConstants';
import {ActionWithPayload} from "../../redux/createAction";
import {MyDocument} from "../../models/types/Document";

const initialState = {
    folders : null,
    files : null
};


export default function documentReducer(state = initialState, action: ActionWithPayload<MyDocument>) {
    switch (action.type) {
        case SET_FOLDERS: {
            return {...state, folders : action.payload.folders};
        }
        case SET_FILES: {
            return {...state, files : action.payload.files};
        }
        default:
            return state;
    }
}

