// authentication reducer
export const CREATE_USER = 'CREATE_USER';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const INIT_USER = 'INIT_USER';
export const SET_INITIALIZED = 'SET_INITIALIZED';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const ACCEPT_CGU = 'ACCEPT_CGU';
export const SET_CGU = 'SET_CGU';
export const SET_IS_AUTHENTICATING = 'SET_IS_AUTHENTICATING';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'


