import {helpGatewayInterface} from "../../../corelogic/usecases/help/helpGatewayInterface";
import {Question} from "../../../corelogic/models/entities/help/question";

export default class InMemoryHelpGateway implements helpGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };

    getQuestions(operationId: number): Promise<Question[]> {
        return Promise.resolve([
            {
                id: 1,
                title: "Je n'arrive pas à changer de participant",
                description: "Pour changer de participant, il suffit de passer par le menu et de clicker sur" +
                    " le bouton 'changer de participant'. Si votre compte est associé à plusieurs participants, " +
                    "vous pourrez alors changer de compte à cet emplacement. Attention, cette action vous déconnectera",
                category : "APP"
            },
            {
                id: 2,
                title: "Je veux télécharger toute mes factures",
                description: "Il vous suffit de cliquer sur les factures à télécharger dans le menu /'Mes Factures/'",
                category : "APP"
            },
            {
                id: 3,
                title: "Je veux quitter l'opération",
                description: "Pour quitter l'opération, rendez vous sur la page Mon Opération et cliquez sur le bouton Quitter l'opération puis confirmez",
                category : "ACC"
            },
            {
                id: 4,
                title: "Je veux contacter le producteur de mon opération",
                description: "Rendez-vous à la page Contacter un participant et envoyez le message voulu à la liste de destinataires",
                category : "ACC"
            },
            {
                id: 5,
                title: "Je veux télécharger toute mes factures",
                description: "Il vous suffit de cliquer sur les factures à télécharger dans le menu /'Mes Factures/'",
                category : "ACC"
            },
            {
                id: 6,
                title: "Je veux télécharger toute mes factures",
                description: "Il vous suffit de cliquer sur les factures à télécharger dans le menu /'Mes Factures/'",
                category : "OPERATION"
            }
        ])
    }
}





