// My Datas
export const SET_CONSODATA = 'SET_CONSODATA';
export const GET_CONSODATA = 'GET_CONSODATA';
export const GET_LATEST_CONSODATA = 'GET_LATEST_CONSODATA';
export const SET_LOCALDATA = 'SET_LOCALDATA';
export const GET_LOCALDATA = 'GET_LOCALDATA';
export const GET_INTERVAL = 'GET_INTERVAL';
export const SET_INTERVAL = 'SET_INTERVAL';
export const GET_CURRENTDATE = 'GET_CURRENTDATE';
export const SET_CURRENTDATE = 'SET_CURRENTDATE';
export const SET_CONSODATA_IS_REAL = 'SET_CONSODATA_IS_REAL';
export const GET_CONSODATA_IS_REAL = 'GET_CONSODATA_IS_REAL';
export const SET_LOCALDATA_IS_REAL = 'SET_LOCALDATA_IS_REAL';
export const GET_LOCALDATA_IS_REAL = 'GET_LOCALDATA_IS_REAL';
export const ADD_ONE_TIME_UNIT = 'ADD_ONE_TIME_UNIT';
export const SUBTRACT_ONE_TIME_UNIT = 'SUBTRACT_ONE_TIME_UNIT';
export const SET_CONSO_DATE_START = 'SET_CONSO_DATE_START';
export const SET_CONSO_DATE_END = 'SET_CONSO_DATE_END';
export const GET_MEASUREMENT_RANGE = 'GET_MEASUREMENT_RANGE'
export const SET_UPLOADED_DATE_RANGE = 'SET_UPLOADED_DATE_RANGE'
