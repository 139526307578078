import {
    INIT_USER,
    SET_CGU,
    SET_INITIALIZED,
    SET_IS_AUTHENTICATING,
    SET_USER
} from './authenticationConstants';
import {combineReducers} from "redux";
import {ActionWithPayload} from "../../redux/createAction";
import {User} from "../../models/entities/authentication/user";
import {Cgu} from "../../models/entities/authentication/cgu";


type TUserReducer = { user: User, initialized: boolean }

function userReducer(state = {}, action: ActionWithPayload<TUserReducer>) {
    switch (action.type) {
        case SET_USER: {
            return action.payload.user; // return action.payload.user.serialize();
        }
        case INIT_USER: {
            return {};
        }
        case SET_INITIALIZED: {
            return {...state, _initialized: action.payload.initialized};
        }
        default:
            return state;
    }
}

const cguInitialState = {
    open: false,
    showAcceptButton: true,
};

type TCguReducer = { cgu: Cgu }

function cguReducer(state = cguInitialState, action: ActionWithPayload<TCguReducer>) {
    switch (action.type) {
        case SET_CGU: {
            return action.payload.cgu;
        }
        default:
            return state;
    }
}


type TIsAuthenticatingReducer = { isAuthenticating: boolean }

function isAuthenticatingReducer(state = true, action: ActionWithPayload<TIsAuthenticatingReducer>) {
    switch (action.type) {
        case SET_IS_AUTHENTICATING: {
            return action.payload.isAuthenticating;
        }
        default:
            return state;
    }
}


export default combineReducers({
    user: userReducer,
    cgu: cguReducer,
    isAuthenticating: isAuthenticatingReducer,
});
