export class Contact{
    participantId:number | null
    name:string
    mail : string

    constructor(participantId:number | null, name:string,  mail:string) {
        this.participantId=participantId
        this.name=name
        this.mail=mail
    }
}