import {VariantType} from "notistack";

export function handleErrorMessage(errorStatus : number) : string {
    if(errorStatus === 401)return "Vous n'êtes pas autorisé à charger cette ressource";
    else if(errorStatus  >= 400 && errorStatus < 500) return "Ressource introuvable"
    else return "Erreur inconnue"
}
export function handleErrorVariant(errorStatus : number) :VariantType {
    if(errorStatus === 401)return 'error';
    else if(errorStatus  >= 400 && errorStatus < 500) return 'error'
    else return 'warning'
}