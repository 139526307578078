import {TConsumption} from "../../models/types/Consumption";
import {createSelector} from "reselect";

export const selectConsumptionData = (state: { consumption: TConsumption; }) => state.consumption;

function haveSolarProducersInMyOperation(productionTypes: string[]): boolean {
    let haveSolarProducers: boolean = false;
    productionTypes && productionTypes.forEach((typeOfProduction: string) => {
        if (typeOfProduction.includes('SOLAIRE') || typeOfProduction.includes('PHOTOVOLTAIQUE')) {
            haveSolarProducers = true
        }
    })
    return haveSolarProducers
}

const selectEcoConseils = createSelector(
    selectConsumptionData,
    consumption => consumption.ecoconseils
);
const selectWeathers = createSelector(
    selectConsumptionData,
    consumption => consumption.weathers
);
const selectProductionTypes = createSelector(
    selectConsumptionData,
    consumption => consumption.typesOfProduction
);
const selectCoordinates = createSelector(
    selectConsumptionData,
    consumption => consumption.coordinates
);


const selectHasSolarProducer = createSelector(
    selectProductionTypes,
    productionTypes => haveSolarProducersInMyOperation(productionTypes)
)
export {selectEcoConseils, selectWeathers, selectProductionTypes, selectCoordinates, selectHasSolarProducer}