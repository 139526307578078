export class EConsoData {
    private _date: string
    private _power: number
    private _powerACC: number

    constructor(date: string, power: number, powerACC: number) {
        this._date = date
        this._power = power
        this._powerACC = powerACC
    }


    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get power(): number {
        return this._power;
    }

    set power(value: number) {
        this._power = value;
    }

    get powerACC(): number {
        return this._powerACC;
    }

    set powerACC(value: number) {
        this._powerACC = value;
    }
}

