export class Coordinates {
     _longitude ?:  string | null;
     _latitude ?:  string | null;

    constructor(long ?: string | null, lat ?:  string | null) {
        this._longitude = long;
        this._latitude = lat
    }

    get longitude(): string | null {
        return this?._longitude || null;
    }

    get latitude(): string | null{
        return this?._latitude  || null;
    }
}