import {
    INIT_OPERATIONS,
    INIT_SELECTED_OPERATION,
    INIT_SORTING, SET_LATEST_DATE_WITH_DATA,
    SET_OPERATION_INFOS,
    SET_OPERATIONS,
    SET_PARTICIPANT_OPERATION,
    SET_PARTICIPANT_TYPE,
    SET_SEARCH,
    SET_SELECTED_OPERATION,
    SET_SORTING
} from './operationConstants';
import {columnTypes} from "../../../config/app-config";

const initialState = {

    operations: [],
    selectedOperation: {},
    operationInfos: {
        consumers: 0,
        producers: 0,
        powerExchanged: 0,
    },
    filter: {
        participantType: 'all',
        search: '',
        sorting: {
            order: 'asc',
            orderBy: 'name',
            type: columnTypes.BILLING,
        },
    },
    latestDateWithData : null
};


export default function operationReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_OPERATIONS: {
            return {...state, operations: action.operations};
        }
        case INIT_OPERATIONS: {
            return {...state, operations: []};
        }

        case SET_SELECTED_OPERATION: {
            return {...state, selectedOperation: {...action.selectedOperation}};
        }
        case SET_PARTICIPANT_OPERATION : {
            return {...state, selectedOperation: {...action.operation}}
        }
        case INIT_SELECTED_OPERATION: {
            return {...state, selectedOperation: null};
        }
        case SET_LATEST_DATE_WITH_DATA : {
            return {...state, latestDateWithData: action.latestDateWithData}
        }

        case SET_SEARCH: {
            return {...state, filter: {...state.filter, search: action.search}};
        }
        case SET_SORTING: {
            return {...state, filter: {...state.filter, sorting: action.sorting}};
        }
        case INIT_SORTING: {
            return {
                ...state,
                filter: {
                    ...state.filter, sorting: {
                        order: 'asc',
                        orderBy: 'name',
                        type: columnTypes.BILLING,
                    }
                }
            };
        }

        case SET_PARTICIPANT_TYPE: {
            return {...state, filter: {...state.filter, participantType: action.participantType}};
        }

        //MyOperation
        case SET_OPERATION_INFOS : {
            return {
                ...state,
                operationInfos: action.opInfos
            }
        }
        default:
            return state;
    }
}

