import React, {Component, ComponentType} from "react";
import {Redirect, Route} from "react-router-dom";
import {createStructuredSelector} from "reselect";
import {selectUser} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {connect, useSelector} from "react-redux";
import {User} from "../../../../../../corelogic/models/entities/authentication/user";
import {selectAppmap} from "../../../../../../corelogic/usecases/navigation/navigationSelector";


type ComponentTypeInterface = {
    path: string,
    Component: ComponentType,
    exact?: boolean,
    user?: User,
    url?: string,
    operations?: any, // todo create model operation ?
    selectedOperation?: any[], // todo create model Operations[] ?
    rest?: any[]
}


const AuthenticatedAndInitializedRoute = ({
                                              Component,
                                              user,
                                              operations,
                                              selectedOperation,
                                              ...rest
                                          }: ComponentTypeInterface) => {
    // If user is not authenticated => redirect to login page
    // If user account is not initialized => redirect to initAccount page

    const appmap = useSelector(selectAppmap)
    if (!user || !user._id) {
        window.location.href = appmap.root;
        return <div/>
    }
    return (
        <Route
            {...rest}
            render={
                props => {
                    return (user._initialized ? <Component/> :
                        <Redirect to={`/initAccount?redirect=${props.location.pathname}${props.location.search}`}/>)
                }
            }
        />
    );
}

let mapStateToProps: (state: any) => any;
mapStateToProps = createStructuredSelector(
    {
        user: selectUser,
    },
);

export default connect<ComponentType>(mapStateToProps)(AuthenticatedAndInitializedRoute);
