import {GET_OPERATION_INFORMATION, SET_OPERATION_INFORMATION,} from "./informationConstants";
import {TEnoField} from "../../models/entities/information/enoField";

export let InformationActions = {
    getOperationInformation() {
        return {type: GET_OPERATION_INFORMATION};
    },
    setOperationInformation(operation: TEnoField[][] | null) {
        return {type: SET_OPERATION_INFORMATION, payload: {operation}};
    },



}

