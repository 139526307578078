export class BillingDetail{

    _id:number;
    _name : string;
    _address : string;
    _postCode: string;
    _city : string;
    _phoneNumber : string;
    _defaultSellingPrice : string;
    _email : string;


    constructor(id:number,name:string, address : string, postCode : string, city : string, email : string,phoneNumber:string,  defaultSellingPrice : string) {
        this._id = id;
        this._name=name;
        this._address = address;
        this._postCode = postCode;
        this._defaultSellingPrice = defaultSellingPrice;
        this._email=email;
        this._city=city;
        this._phoneNumber = phoneNumber;
    }

    serialize() {
        return {
            id: this._id,
            name: this._name,
            address: this._address,
            postCode : this._postCode,
            city:this._city,
            defaultSellingPrice : this._defaultSellingPrice,
            email:this._email,
            phoneNumber:this._phoneNumber
        }
    }

}
