import {authenticationGatewayInterface} from "../../../corelogic/usecases/authentication/authenticationGatewayInterface";
import {User} from "../../../corelogic/models/entities/authentication/user";

export default class InMemoryAuthenticationGateway implements authenticationGatewayInterface {
    isParticipant: any;
    hasAcceptedCgu: boolean;
    error: any;
    constructor() {
        this.hasAcceptedCgu = true;
        this.isParticipant = undefined;
        this.error = undefined;
    };

    setError = (error: boolean) => {
        this.error = error;
    };

    getUser = () => {
        return Promise.resolve(new User(1, 'participant',"new@email.fr" ,true, this.hasAcceptedCgu, 'consumer'))
        // return new User(2,'participant', true, this.hasAcceptedCgu, 'consumer', true);
    }

    logOut = () => {
        return Promise.resolve( {success: true})
    };

    logIn = ({email, password} : {email:string, password:string}) => {
        return Promise.resolve( new User(1, 'participant', "new@email.fr", true, this.hasAcceptedCgu, 'consumer'))
        // return new User(2,'participant', true, this.hasAcceptedCgu, 'consumer', true);
    };

    acceptCgu = (cguId: number) => {
        return {
            id: 1,
            userId: 1,
            cguId,
        }
    };


    changePassword({oldPassword, newPassword}: { oldPassword : string, newPassword : string }):Promise<{ success: boolean }>{
    return Promise.resolve({success: true});
    }
}
