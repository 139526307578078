import React, {Component, Props} from "react";
import {Route, Switch} from "react-router-dom";


interface defaultComponentType {
    path:string,
    Component: any,
    exact ?: boolean,
    props : Props<any>
}

export default ({path, Component, ...rest}: defaultComponentType ) => {
    return <Switch>
        {console.log("UnauthenticatedRoute")}
        <Route
            {...rest}
            render={props =>
                <Component {...props} />
            }
        />
    </Switch>;
}

