import {
    GET_CONTACTS,
    SEND_MAIL_TO_QUIT_OPERATION,
    SEND_NEW_MESSAGE,
    SET_CONTACTS,
    SET_NEW_MESSAGE,
    SET_OPERATION_LEFT_STATUS
} from "./contactConstants";
import {Contact} from "../../models/entities/contact/contact";
import {Message} from "../../models/entities/contact/message";


export let ContactActions = {

    getContacts(operationId : number) {
        return {type: GET_CONTACTS, payload : {operationId}};
    },
    setContacts(contacts:Contact[] | null) {
        return {type: SET_CONTACTS, payload : {contacts}};
    },
    sendNewMessage({recipient, message}:{recipient:string[], message: string | null}) {
        return {type: SEND_NEW_MESSAGE, payload: {message, recipient}};
    },
    setNewMessage(newMessage : Message) {
        return {type: SET_NEW_MESSAGE, payload: {newMessage}};
    },
    sendMailToQuitOperation(){
        return {type : SEND_MAIL_TO_QUIT_OPERATION, payload:{}}
    },
    setOperationLeftStatus(message : string, variant : string) {
        return {type : SET_OPERATION_LEFT_STATUS, payload : {message, variant}}
    }
}

