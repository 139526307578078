import {GET_QUESTIONS} from "./helpConstants";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {HelpActions} from "./helpActions";
import {helpGatewayInterface} from "./helpGatewayInterface";
import {Question} from "../../models/entities/help/question";


function getCategoriesFromQuestions(questions: Question[]): Set<string> {
    /*
    * entry : [{id:1,category:"APP",...},{id:1,category:"ACC",...}, {id:1,category:"APP",...}, {id:1,category:"OPERATION",...}]
    * exit : ["APP", "ACC", "OPERATION"]
    * */
    let categories: Set<string> = new Set()
    questions.forEach((question: Question) => {
        categories.add(question.category)
    })
    return categories
}

function sortQuestionsByCategory(questions: Question[]): { category: string, questions: Question[] }[] {
    /*
    * entry : [{id:1,category:"APP",...},{id:1,category:"ACC",...}, {id:1,category:"APP",...}, {id:1,category:"OPERATION",...}]
    * exit : [{category : "APP, [{id:1,category:"APP",...}, {id:2,category:"APP",...}]}, {category : "ACC, [{id:3,category:"ACC",...}, {id:4,category:"ACC",...}]}, ...]
    * */
    let questionsSortedByCategories: { category: string, questions: Question[] }[] = []
    const questionsCategories = getCategoriesFromQuestions(questions)
    questionsCategories.forEach((category: string) => {
        let sortedQuestions: Question[] = [];
        questions.forEach((question: Question) => {
            if (question.category === category) sortedQuestions.push(question)
        })
        questionsSortedByCategories.push({category: category, questions: sortedQuestions})
    })
    return questionsSortedByCategories
}

function* getQuestions(action: any) {
    const { operationId } = action;
    try {
        const helpGatewayInterface: helpGatewayInterface = yield getContext('helpGatewayContext');
        const questions: Question[] = yield call(helpGatewayInterface.getQuestions, operationId);
        const questionsSortedByCategories: { category: string, questions: Question[] }[] = sortQuestionsByCategory(questions)

        yield put(
            HelpActions.setQuestions(questionsSortedByCategories),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* getQuestionsSaga() {
    yield takeLatest(GET_QUESTIONS, getQuestions);
}

export default [getQuestionsSaga];
