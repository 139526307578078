import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {ComponentType, Fragment, useEffect} from "react";
import {AuthenticationActions} from "../../../corelogic/usecases/authentication/authenticationActions";
import {NavigationActions} from "../../../corelogic/usecases/navigation/navigationActions";
import {History, LocationState} from 'history';
import {withSnackbar} from "notistack";
import {
    selectCgu,
    selectIsAuthenticating,
    selectUser
} from "../../../corelogic/usecases/authentication/authenticationSelector";
import {createStructuredSelector} from "reselect";
import {Authentication} from "../../../corelogic/models/types/Authentication";
import {Cgu} from "../../../corelogic/models/entities/authentication/cgu";
import CGUPopup from "./Components/global/PopUps/CGUPopup";
import Routes from "./Routes";
import {compose} from "redux";
import {User} from "../../../corelogic/models/entities/authentication/user";
import {initOperations, initSelectedOperation} from "../../../corelogic/usecases/operation/operationActions";

type TApp = {
    history: History<LocationState>,
    cgu: any,
    isAuthenticating: boolean,
    getUser : () => any,
    setCgu: any,
    acceptCgu: any,
    initSelectedOperation: any,
    initOperations: any,
    getAppmap: any,
}
const App = ({
                 history,
                 cgu,
                 isAuthenticating,
                 setCgu,
                 getUser,
                 acceptCgu,
                 initSelectedOperation,
                 initOperations,
                 getAppmap,
             }: TApp) => {
    useEffect(() => {
        document.title = "Enoconso"
        getUser();
        getAppmap();
    }, []);

    const handleCloseCGU = () => {
        cgu.showAcceptButton && AuthenticationActions.logOut(history);
        setCgu({open: false});
    };

    const handleLogOut = () => {
        AuthenticationActions.logOut(history);
        initSelectedOperation();
        initOperations();
    };

    const handleAcceptCgu = (cguId: Number) => {
        acceptCgu(cguId, history);
    }

    return (
        <Fragment>
            <CGUPopup handleCloseCGU={handleCloseCGU}
                      cguPopup={cgu}
                      acceptCGU={handleAcceptCgu}
            />
            {!isAuthenticating && <Routes childProps={{handleLogout: handleLogOut}}/>}
        </Fragment>
    );
}
let mapStateToProps: (state: { authentication: Authentication }) => { cgu: Cgu; isAuthenticating: boolean };

mapStateToProps = createStructuredSelector(
    {
        cgu: selectCgu,
        isAuthenticating: selectIsAuthenticating,
        user: selectUser,
    },
);

const mapDispatchToProps = {
    getUser: AuthenticationActions.getUser,
    setCgu: AuthenticationActions.setCgu,
    initUser: AuthenticationActions.initUser,
    acceptCgu: AuthenticationActions.acceptCgu,
    logOut: AuthenticationActions.logOut,
    getAppmap: NavigationActions.getAppmap,
    initOperations,
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withSnackbar, withRouter)(App) as ComponentType;
