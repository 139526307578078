import {createSelector} from 'reselect'
import {TDatas} from "../../models/types/Datas";
import {EConsoData} from "../../models/entities/datas/consoData";

const datas = (state: { datas: TDatas; }) => state.datas;

// myDatas
const selectSelectedDatasInterval = createSelector(
    datas,
    datas => datas && datas.interval
)
const selectDateFrom = createSelector(
    datas,
    datas => datas && datas.dateFrom
)
const selectDateTo = createSelector(
    datas,
    datas => datas && datas.dateTo
)
const selectSelectedDatasCurrentDate = createSelector(
    datas,
    datas => datas && datas.currentDate
)

const selectSelectedDatasConsoDataUnformatted = createSelector(
    datas,
    datas => datas && datas.consoData
)
const selectSelectedDatasConsoData = createSelector(
    selectSelectedDatasConsoDataUnformatted,
    consoData => consoData.map((consoData : EConsoData)=> {
        consoData.power = consoData.power - consoData.powerACC
        return consoData
    })
)
const selectSelectedDatasLocalData = createSelector(
    datas,
    datas => datas && datas.localData
)
const selectSelectedDatasLocalDataIsReal = createSelector(
    datas,
    datas => datas && datas.localDataIsReal
)
const selectSelectedDatasConsoDataIsReal = createSelector(
    datas,
    datas => datas && datas.consoDataIsReal
)
const selectUploadedDateRange = createSelector(
    datas,
    datas => datas && datas.uploadedDateRange
)
const selectUploadedDateRangeFrom = createSelector(
    selectUploadedDateRange,
    uploadedDateRange => uploadedDateRange && uploadedDateRange.from
)
const selectUploadedDateRangeTo = createSelector(
    selectUploadedDateRange,
    uploadedDateRange => uploadedDateRange && uploadedDateRange.to
)

export {
    selectUploadedDateRangeFrom,
    selectUploadedDateRangeTo,
    selectSelectedDatasInterval,
    selectSelectedDatasConsoData,
    selectSelectedDatasLocalData,
    selectSelectedDatasCurrentDate,
    selectSelectedDatasLocalDataIsReal,
    selectDateFrom,
    selectDateTo,
    selectSelectedDatasConsoDataIsReal,
    selectUploadedDateRange
}
