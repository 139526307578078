import {DocumentFile} from "./documentFile";

export class DocumentFolder{

    _filesChild: DocumentFile[] | null ;
    _foldersChild: DocumentFolder[] | null;
    _name: string;

    constructor(name:string, filesChild:DocumentFile[]| null , foldersChild:DocumentFolder[]| null) {
        this._name=name
        this._filesChild = filesChild;
        this._foldersChild=foldersChild;
    }

    addFilesChild(filesChild: DocumentFile) {
        this._filesChild?.push(filesChild)
    }

    addFoldersChild(foldersChild: DocumentFolder) {
        this._foldersChild?.push(foldersChild)
    }
}