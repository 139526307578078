import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {GET_CONTACTS, SEND_MAIL_TO_QUIT_OPERATION, SEND_NEW_MESSAGE} from "./contactConstants";
import {ContactActions} from "./contactActions";
import {contactGatewayInterface} from "./contactGatewayInterface";
import {Contact} from "../../models/entities/contact/contact";
import {selectCurrentParticipantId, selectCurrentParticipantOperationId} from "../participants/participantsSelector";
import {MailSentResponse} from "../../models/entities/contact/mailSentResponse";
import {Message, Status} from "../../models/entities/contact/message";
import {selectOperationOrganizerId, selectSelectedOperation} from "../operation/operationSelector";
import {EOperation} from "../../models/entities/operation/operation";

function* getContacts() {
    try {
        const operationId:number = yield select(selectCurrentParticipantOperationId)
        const organizerId:number = yield select(selectOperationOrganizerId)
        const contactGatewayInterface: contactGatewayInterface = yield getContext('contactGatewayContext');
        const contacts: Contact[] = [new Contact(null, "Support Enogrid", "support@enogrid.com")]
        const contactPMO : {username : string, email : string} = yield call(contactGatewayInterface.getPMO, organizerId)
        contacts.push(new Contact(null, contactPMO.username + ', (PMO) - ' + contactPMO.email, contactPMO.email))
        const recipients :Contact[]  = yield call(contactGatewayInterface.getContacts, operationId )
        const contactsConcat : Contact[] = contacts.concat(recipients)
        yield put(
            ContactActions.setContacts(contactsConcat),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}


function* sendNewMessage(action : any) {
    try {
        const {recipient, message} = action.payload
        const contactGatewayInterface: contactGatewayInterface = yield getContext('contactGatewayContext');
        const mailSentResponse: MailSentResponse = yield call(contactGatewayInterface.postNewMessage, recipient, message );
        const {accepted, rejected} = mailSentResponse

        const newMessage:Message = new Message(message, [])

        accepted.map((email:string) => {
            newMessage.recipient.push({email:email,status:Status.ACCEPTED})
        })
        rejected.map((email:string) => {
            newMessage.recipient.push({email:email,status:Status.REJECTED})
        })
        yield put (ContactActions.setNewMessage(newMessage))
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}
function* sendMailToQuitOperation(action : any) {
    try {
        const selectedOperation:EOperation = yield select(selectSelectedOperation)

        const participantId:number = yield select(selectCurrentParticipantId)

        const contactGatewayInterface: contactGatewayInterface = yield getContext('contactGatewayContext');
        const responseStatus: number = yield call(contactGatewayInterface.sendMailToQuitOperation, selectedOperation, participantId);
        if(responseStatus === 200){
            yield put (ContactActions.setOperationLeftStatus(
                "Nous avons bien pris en compte votre demande de départ de l'ACC N° "+selectedOperation.name+", nous reviendrons vers vous rapidement pour vous communiquer la date de départ effective.", "info"))
        }
        else {
            yield put (ContactActions.setOperationLeftStatus(
                "Une erreur s'est produite, et nous n'avons pas pu transmettre votre demande de sortie d'opération.", "error"))
        }

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* sendNewMessageSaga() {
    yield takeLatest(SEND_NEW_MESSAGE, sendNewMessage);
}
export function* sendMailToQuitOperationSaga() {
    yield takeLatest(SEND_MAIL_TO_QUIT_OPERATION, sendMailToQuitOperation);
}
export function* getContactsSaga() {
    yield takeLatest(GET_CONTACTS, getContacts);
}

export default [getContactsSaga, sendNewMessageSaga,sendMailToQuitOperationSaga];
