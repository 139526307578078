import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {
    GET_PARTICIPANTS,
    UPDATE_CURRENT_PARTICIPANT_BILLING_DETAIL,
    UPDATE_CURRENT_PARTICIPANT_ENEDIS_DETAIL,
} from "./participantsConstants";
import {participantsGatewayInterface} from "./participantsGatewayInterface";
import {ParticipantsActions} from "./participantsActions";
import {EParticipant} from "../../models/entities/participant/participant";
import {selectCurrentParticipantId,} from "./participantsSelector";
import {AuthenticationActions} from "../authentication/authenticationActions";
import {EnedisDetail} from "../../models/entities/participant/enedisDetail";
import {BillingDetail} from "../../models/entities/participant/billingDetail";


function* getParticipants() {
    try {
        const participantsGatewayInterface: participantsGatewayInterface = yield getContext('participantsGatewayContext');
        const participants: EParticipant[] = yield call(participantsGatewayInterface.getParticipantsByJwt);
        yield put(ParticipantsActions.setParticipants(participants));

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}


function* updateCurrentParticipantEnedisDetail(action: any) {
    try {
        const {enedisDetail} = action.payload
        const participantId:number = yield select(selectCurrentParticipantId)
        const participantsGateway: participantsGatewayInterface = yield getContext('participantsGatewayContext');
        const enedisDetailUpdated:EnedisDetail = yield call(participantsGateway.updateEnedisDetail, {enedisDetail : enedisDetail, participantId : participantId})
        yield put(ParticipantsActions.setEnedisDetail(enedisDetailUpdated))
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* updateCurrentParticipantBillingDetail(action: any) {
    try {
        const {billingDetail} = action.payload
        const participantId:number = yield select(selectCurrentParticipantId)
        const participantsGateway: participantsGatewayInterface = yield getContext('participantsGatewayContext');
        const billingDetailUpdated:BillingDetail = yield call(participantsGateway.updateBillingDetail, {billingDetail : billingDetail, participantId : participantId})
        yield put(ParticipantsActions.setBillingDetail(billingDetailUpdated))
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}


export function* updateCurrentParticipantEnedisDetailSaga() {
    yield takeLatest(UPDATE_CURRENT_PARTICIPANT_ENEDIS_DETAIL, updateCurrentParticipantEnedisDetail);
}
export function* updateCurrentParticipantBillingDetailSaga() {
    yield takeLatest(UPDATE_CURRENT_PARTICIPANT_BILLING_DETAIL, updateCurrentParticipantBillingDetail);
}


export function* getParticipantsSaga() {
    yield takeLatest(GET_PARTICIPANTS, getParticipants);
}


export default [getParticipantsSaga, updateCurrentParticipantEnedisDetailSaga, updateCurrentParticipantBillingDetailSaga];
