import {
    GET_LATEST_DATE_WITH_DATA,
    GET_OPERATION_INFOS,
    GET_OPERATION_PARTICIPANTS,
    GET_OPERATIONS,
    GET_PARTICIPANT_OPERATION,
    INIT_OPERATIONS,
    INIT_SELECTED_OPERATION,
    INIT_SORTING, SET_LATEST_DATE_WITH_DATA,
    SET_OPERATION_INFOS,
    SET_OPERATIONS,
    SET_PARTICIPANT_OPERATION,
    SET_PARTICIPANT_TYPE,
    SET_SEARCH,
    SET_SELECTED_OPERATION,
    SET_SORTING,
    UPDATE_OPERATION,
    UPDATE_PRESIGNED_LOGOS,
} from "./operationConstants";
import {EOperation} from "../../models/entities/operation/operation";


export function getOperations(role: any) {
    return {type: GET_OPERATIONS, role};
}

export function setOperations(operations: any) {
    return {type: SET_OPERATIONS, operations};
}

export function setSelectedOperation(selectedOperation: EOperation) {
    return {type: SET_SELECTED_OPERATION, selectedOperation};
}

export function initSelectedOperation() {
    return {type: INIT_SELECTED_OPERATION};
}

export function initOperations() {
    return {type: INIT_OPERATIONS};
}

export function getOperationParticipants(operation: any, dateFrom?: any, dateTo?: any) {
    return {type: GET_OPERATION_PARTICIPANTS, operation, dateFrom, dateTo};
}


export function updateOperation({operation, formData}: any) {
    return {type: UPDATE_OPERATION, operation, formData};
}

export function setSearch(search: any) {
    return {type: SET_SEARCH, search};
}

export function setSorting(sorting: any) {
    return {type: SET_SORTING, sorting};
}

export function initSorting() {
    return {type: INIT_SORTING};
}


export function setParticipantType(participantType: any) {
    return {type: SET_PARTICIPANT_TYPE, participantType};
}

export function updatePresignedLogos(operations: any) {
    return {type: UPDATE_PRESIGNED_LOGOS, operations};
}

export function setParticipantOperation(operation : any) {
    return {type : SET_PARTICIPANT_OPERATION, operation}
}
export function getParticipantOperation(participantId : number) {
    return {type : GET_PARTICIPANT_OPERATION, participantId}
}

//MyOperation Actions

export function getOperationInfos() {
    return {type: GET_OPERATION_INFOS};
}

export function setOperationInfos(opInfos: {producers:number, consumers:number, powerExchanged : number}) {
    return {type: SET_OPERATION_INFOS, opInfos};
}


export function setLatestDateWithData(latestDateWithData : Date){
    return {type : SET_LATEST_DATE_WITH_DATA, latestDateWithData}
}
export function getLatestDateWithData(){
    return {type : GET_LATEST_DATE_WITH_DATA}
}