import {DOWNLOAD_FILE, GET_DOCUMENT, SET_FILES, SET_FOLDERS} from "./documentConstants";
import {DocumentFolder} from "../../models/entities/document/documentFolder";
import {DocumentFile} from "../../models/entities/document/documentFile";

export let DocumentActions = {

    getDocument() {
        return {type: GET_DOCUMENT};
    },
    setFolders(folders: DocumentFolder[] | null) {
        return {type: SET_FOLDERS, payload: {folders}};
    },
    setFiles(files: DocumentFile[] | null) {
        return {type: SET_FILES, payload: {files}};
    },
    downloadFile(fileId: number) {
        return {type: DOWNLOAD_FILE, payload: {fileId}};
    },

}

