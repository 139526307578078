export class BillHistory{

    _billingPeriodStart: string;
    _billingPeriodEnd:string
    _price: number;
    _documentId : number;

    constructor( billingPeriodStart : string, billingPeriodEnd : string, price:number, documentId : number) {
        this._billingPeriodEnd = billingPeriodEnd;
        this._billingPeriodStart = billingPeriodStart;
        this._price= price;
        this._documentId = documentId
    }
}